import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Stack, TextField,} from "@mui/material"
import AddIcon from '@mui/icons-material/Add';
import React from "react"
import { Aspect, } from "./Api"
import {v4 as uuidv4} from 'uuid';

import _ from "underscore"

export default (props: { existing:Aspect | undefined, onComplete: (a:Aspect) => void, onCancel: () => void }) => {
    const {existing, onComplete } = props
    const [id, setId] = React.useState(existing?.id || "")
    const [title, setTitle] = React.useState(existing?.name || "")
    const [description, setDescription] = React.useState(existing?.description || "")
    const [error, setError] = React.useState<string>()

    const isNew = !(id && id.length>0)

    const doSave = () => {
        const aspect:Aspect = {
            ... existing,
            id:id,
            name:title,
            description:description,
            values:[],
        }
        onComplete(aspect)
    }


    return (<Dialog open={true} fullWidth={true} fullScreen={true}>
        <DialogTitle>{isNew ? "Add" : "Edit"} Aspect</DialogTitle>

        <DialogContent>
            {error && <Alert color="error">{error}</Alert>}
            {!isNew && <TextField
                autoFocus
                margin="dense"
                id="ID"
                label="ID"
                value={id}
                disabled={true}
                onChange={e => setId((e.target.value))}
                fullWidth
                variant="standard"
            />}
            <TextField
                margin="dense"
                id="Title"
                label="Title"
                value={title}
                onChange={e => setTitle((e.target.value))}
                fullWidth
                variant="standard"
            />
            <TextField
                margin="dense"
                id="Description"
                label="Description"
                value={description}
                onChange={e => setDescription((e.target.value))}
                fullWidth
                multiline
                rows={8}
                variant="filled"
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={props.onCancel}>Cancel</Button>
            <Button onClick={doSave}>Save</Button>
        </DialogActions>
    </Dialog>)
}
