import { CSSProperties, useState } from "react"
import { PlanOverviewDto } from "./Api"
import { CircularProgress, IconButton, Tooltip } from "@mui/material"
import RefreshIcon from '@mui/icons-material/Refresh';
import { get } from './Http';

export default (props: { plan: PlanOverviewDto, style?: CSSProperties, onReload: () => void }) => {
  const { plan, style, onReload } = props

  const [isWorking, setWorking] = useState(false)

  if (isWorking) {
    return <CircularProgress size={20} />
  } else {
    return <Tooltip title="ReSync with Remote Source">
      <IconButton style={style} onClick={() => {
        setWorking(true)
        get("/plans/" + plan.id + "/latest?format=legacyburn", (result: any) => {
          onReload()
          setWorking(false)
        })
      }} >
        <RefreshIcon />
      </IconButton>
    </Tooltip>
  }
}