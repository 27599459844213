import { Alert, AppBar, Button, Dialog, DialogContent, DialogContentText, Fab, IconButton, TextField, Toolbar, Typography } from "@mui/material"
import React, { useState } from "react"
import { Components, PlanConfig, } from "./Api"

import { post } from "./Http"

import _ from "underscore"
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import UploadIcon from '@mui/icons-material/Upload';
import ItemEditorDialog from "./ItemEditorDialog";

const UploadDialog = (props: { planId: string, onComplete: () => void }) => {
    const { planId, onComplete } = props
    const [value, setValue] = React.useState("")
    const [error, setError] = React.useState<string>()
    const path = `/api/plans/${planId}/components/items.csv`
    const doUpload = () => {
        // ()=>value ? props.onComplete(value) : props.onCancel()

        post(value,
            path,
            onComplete,
            (xhr) => { setError(`Error: ${xhr.statusText} ${xhr.responseText}`) }
        )
    }


    return (<Dialog open={true} fullScreen>
        {/* <DialogTitle>Upload CSV Data</DialogTitle> */}
        <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={onComplete}
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                    Upload CSV Data
                </Typography>
                <Button autoFocus color="inherit" onClick={doUpload}>
                    save
                </Button>
            </Toolbar>
        </AppBar>
        <DialogContent>
            <DialogContentText>
                {/* Or, you can do something like this: 
            <pre>
curl -d @path-to-my-file/file.csv -H 'Content-Type: text/csv' {window.location.protocol}://{window.location.hostname}:{window.location.port}{path}`
            </pre> */}
            </DialogContentText>
            <TextField
                multiline={true}
                autoFocus
                margin="dense"
                id="CSV"
                label="CSV"
                type="text"
                value={value}
                onChange={e => setValue((e.target.value))}
                fullWidth
                rows={15}
                variant="filled"
            />
            {error && <Alert color="error">{error}</Alert>}
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={onComplete}>Cancel</Button>
          <Button onClick={doUpload}>Save</Button>
        </DialogActions> */}
    </Dialog>)
}

export default (props:{planId:string, isEditable:boolean, config:PlanConfig, components:Components|undefined, respondToUpdates:()=>void})=>{
    const {planId, config, components, respondToUpdates} = props
    const [showUploadDialog, setShowUploadDialog] = useState(false)
    const [showAddDialog, setShowAddDialog] = useState(false)
    const isEditable = props.isEditable && (config?.components?.itemsAreEditable == true)

    const hideEditor = () => {
        setShowAddDialog(false)
    }

    const doUpload = () => {
        setShowUploadDialog(true)
    }

    const handleAddComplete = ()=>{
        hideEditor()
        respondToUpdates()
    }

    return <>
        {(showUploadDialog && planId) && <UploadDialog planId={planId} onComplete={() => { setShowUploadDialog(false); respondToUpdates() }} />}
        {(showAddDialog && planId && components) && <ItemEditorDialog planId={planId} existing={undefined} config={config} components={components} onComplete={handleAddComplete} onCancel={hideEditor} />}

        {(config?.components && !config.components?.itemsAreEditable) && <Fab color="primary" aria-label="add" onClick={doUpload}>
            <UploadIcon />
        </Fab>}
        {(isEditable && config?.components && config.components?.itemsAreEditable) && <>
            <Fab color="primary" aria-label="add" onClick={() => setShowAddDialog(true)}>
                <AddIcon />
            </Fab>
        </>}
    </>
}