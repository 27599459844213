
type HttpPayload = string|null|FormData

export function get<T>(resource:string, fn:(responseText:T)=>void){
    var oReq = new XMLHttpRequest();
    oReq.addEventListener("load", function(){
        fn(JSON.parse(this.responseText))
    });
    oReq.open("GET", resource);
    oReq.send();
}


export let send = (method:string, payload:HttpPayload, url:string, fn:(responseText:string)=>void, err?:(err:XMLHttpRequest)=>void)=>{
    var xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function() {
        //console.log("Fetching ...")
        if (this.readyState === 4) {
          if(this.status === 200){
            console.log("debug 200 code")
           fn(xhttp.responseText);
          }else{
              if(err) err(xhttp)
          }
        }
    };
    xhttp.open(method, url, true);
    xhttp.send(payload);
}
export let sendFull = (method:string, payload:HttpPayload, url:string, fn:(status:number, responseText:string)=>void)=>{
    var xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function() {
        //console.log("Fetching ...")
        if (this.readyState === 4) {
          fn(this.status, xhttp.responseText);
        }
    };
    xhttp.open(method, url, true);
    xhttp.send(payload);
}


export let post = (payload:HttpPayload, url:string, fn:(responseText:string)=>void, onError?:(err:XMLHttpRequest)=>void)=>{
    send("POST", payload, url, fn, onError)
}

export let patch = (payload:HttpPayload, url:string, fn:(responseText:string)=>void, onError?:(err:XMLHttpRequest)=>void)=>{
    send("PATCH", payload, url, fn, onError)
}

export let put = (payload:HttpPayload, url:string, fn:(responseText:string)=>void, onError?:(err:XMLHttpRequest)=>void)=>{
    send("PUT", payload, url, fn, onError)
}