import { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { Alert, Box, Card, CardContent, CircularProgress, Typography } from "@mui/material"
import { Link as MUILink } from "@mui/material"
import Grid from '@mui/material/Unstable_Grid2'

import { patch } from "./Http"

import { Components, ItemComponent, ItemStatus, PlanConfig, PlanOverviewDto } from "./Api"
import { getActualPlan, getComponents, getCurrentIteration, getPlanConfig, getPlanOverview, setItemOrder, updateItemStatus } from "./api-actions"

import { isFinishedStatus } from "./BacklogView"
import { ReorderableList } from "./DragAndDrop"
import PlanScreenLayout from "./PlanScreenLayout"
import { PlanSubmenu } from "./PlanSubmenu"
import PlanTimeSelectDialog from "./PlanTimeSelectDialog"
import { findLastStatusChange, findLastStatusChangeAsOf } from "./item-logic"
import { useViewport } from "react-viewport-hooks"
import PlanControlsBand from "./PlanControlsBand"

export default () => {
    const { planId } = useParams()
    const [isFetching, setFetching] = useState(true)
    const [plan, setPlan] = useState<PlanOverviewDto>()
    const [config, setConfig] = useState<PlanConfig>()
    const [components, setComponents] = useState<Components>()
    const [error, setError] = useState<string>()
    const [overview, setOverview] = useState<PlanOverviewDto>()
    const [threshold, setThreshold] = useState<number>()
    const [showTimeSelectDialog, setShowTimeSelectDialog] = useState(false)
    const versionId = "current"

    const refresh = () => {
        setFetching(true)

        if (planId) {
            Promise.all([
                getPlanOverview(planId).then(setOverview),
                getActualPlan(planId).then(setPlan),
                getPlanConfig(planId).then(setConfig),
                getComponents(planId, versionId).then(setComponents),
                getCurrentIteration(planId).then(i => {
                    setThreshold(i.start)
                }),
            ]).catch(e => {
                console.log("Error:::", e)
                setError(e)
            }).finally(() => setFetching(false))
        }
    }

    useEffect(refresh, [planId])



    const activeComponents = threshold ? components?.items.filter(i => {

        const sc = findLastStatusChangeAsOf(i, threshold, components)

        return sc ? !isFinishedStatus(sc) : true
    }) : []

    const todo: ItemComponent[] = []
    const inProgress: ItemComponent[] = []
    const done: ItemComponent[] = []

    components && activeComponents?.forEach(i => {
        const sc = findLastStatusChange(i, components)

        switch (sc?.status) {
            case ItemStatus.done:
                done.push(i)
                break;
            case ItemStatus.in_progress:
                inProgress.push(i)
                break;
            default:
                todo.push(i)
                break;

        }
    })


    const insertAfter = (item: ItemComponent, after: ItemComponent | undefined, columnItems: ItemComponent[], columnStatus: ItemStatus) => {

        if (components) {
            const visibleItems = columnItems
            const beforeFirst = (): ItemComponent | undefined => {

                const firstVisible = visibleItems && visibleItems[0]
                const firstVisibleIdx = columnItems?.findIndex(i => i.id == firstVisible?.id)

                const beforeFirst = (columnItems && firstVisibleIdx && firstVisibleIdx > 0) ? columnItems[firstVisibleIdx - 1] : undefined
                return beforeFirst
            }

            const status = findLastStatusChange(item, components)?.status
            const previous = after || beforeFirst()
            console.log(item.id, "goes after", after?.id, "/", previous?.id)
            setItemOrder({ id: item.id, after: previous?.id }, planId!!)
                .then(() => {
                    if (status != columnStatus) {
                        console.log("Needs status change", status, columnStatus)
                        return updateItemStatus(columnStatus, item, planId!!)
                    } else {
                        console.log("No status change necessary", status, columnStatus)
                        return true
                    }
                })
                .then(refresh)


        }

    }

    const makeInsertFn = (items: ItemComponent[], columnStatus: ItemStatus): ((item: ItemComponent, after: ItemComponent | undefined) => void) => {

        return (i, after) => insertAfter(i, after, items, columnStatus)
    }

    const { vw, vh } = useViewport()

    return (<>
        {(planId && overview && threshold) && <PlanScreenLayout
            // name={"workflow"}
            overview={overview}
            actionsBar={<>
                {isFetching && <CircularProgress />}
                {config && <PlanControlsBand
                    planId={planId!!}
                    versionId={versionId}
                    isEditable={true}
                    plan={plan}
                    config={config}
                    components={components}
                    respondToUpdates={refresh} />}
            </>}
            controls={<></>}
        >
            {error && <Alert color="error">{error}</Alert>}
            <PlanSubmenu planId={planId!!} components={components} />

            <Typography style={(vw > 800) ? { textAlign: "right" } : { textAlign: "center", marginTop: "20px" }}>Progress Since <MUILink style={{ cursor: "pointer", color: '#222' }} onClick={() => setShowTimeSelectDialog(true)} >{new Date(threshold).toLocaleString()}</MUILink> </Typography>
            {showTimeSelectDialog && <PlanTimeSelectDialog
                planId={planId}
                value={threshold}
                onCancel={() => setShowTimeSelectDialog(false)}
                onSelect={t => {
                    setThreshold(t)
                    setShowTimeSelectDialog(false)
                }} />}

            <Box style={{ minHeight: "40px" }}>
                {/* Spacer */}
            </Box>
            
            {((activeComponents ?? []).length == 0) && <Typography>This plan is empty </Typography>}
            {((activeComponents ?? []).length > 0) && <>
                <Grid container>
                    <Grid xs={4}><StackLabel>Todo</StackLabel></Grid>
                    <Grid xs={4}><StackLabel>In-Progress</StackLabel></Grid>
                    <Grid xs={4}><StackLabel>Done</StackLabel></Grid>

                    <Grid xs={4}><ItemStack items={todo} planId={planId} insertAfter={makeInsertFn(todo, ItemStatus.todo)} /></Grid>
                    <Grid xs={4}><ItemStack items={inProgress} planId={planId} insertAfter={makeInsertFn(inProgress, ItemStatus.in_progress)} /></Grid>
                    <Grid xs={4}><ItemStack items={done} planId={planId} insertAfter={makeInsertFn(done, ItemStatus.done)} /></Grid>
                </Grid>
            </>}
        </PlanScreenLayout>}


    </>)
}

const StackLabel = (props: { children: string }) => {
    const { children } = props
    return (<>
        <Box style={{ textAlign: "center" }}><Typography variant="h6">{children}</Typography></Box>
    </>)
}

const ItemStack = (props: { items: ItemComponent[], planId: string, insertAfter: (item: ItemComponent, after: ItemComponent | undefined) => void }) => {
    const { items, planId, insertAfter } = props
    return (<>
        {/* <Stack spacing={2} style={{margin:"10px"}}> */}
        <ReorderableList
            useThumbs={false}
            items={items}
            editable={true}
            insertAfter={insertAfter}
            renderItem={i => <ItemCard item={i} planId={planId} />}
        />

        {/* </Stack> */}
    </>)
}

const ItemCard = (props: { item: ItemComponent, planId: string }) => {
    const { item, planId } = props
    return (<>
        <Card style={{ marginBottom: "5px" }}><CardContent><Link style={{ color: '#222' }} className="underline-on-hover" to={`/planview/${planId}/items/${item.id}`}>{item.title}</Link></CardContent></Card>
    </>)
}
