import { useState, useEffect, useRef, useLayoutEffect } from 'react';

const useTimeout = (callback: () => void, delay: number) => {

    // Creating a ref  
    const savedCallback = useRef<() => void>();

    // To remember the latest callback . 
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Setting and clearing up a timeout 
    useEffect(() => {
        const func = () => {
            const c = savedCallback.current
            c && c()
        }
        if (delay !== null) {
            let id = setTimeout(func, delay);
            return () => clearTimeout(id);
        }
    }, [delay]);
};


export function useInterval(callback: () => void, delay: number | null) {
    const savedCallback = useRef(callback)

    // Remember the latest callback if it changes.
    useIsomorphicLayoutEffect(() => {
        savedCallback.current = callback
    }, [callback])

    // Set up the interval.
    useEffect(() => {
        // Don't schedule if no delay is specified.
        // Note: 0 is a valid value for delay.
        if (!delay && delay !== 0) {
            return
        }

        const id = setInterval(() => savedCallback.current(), delay)

        return () => clearInterval(id)
    }, [delay])
}

export const useIsomorphicLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect