
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Stack, TextField, Typography, } from "@mui/material"
import React, { ReactNode, useEffect, useState } from "react"
import { ItemComponent, PlanConfig, InitialBacklogPosition, NewItemComponent, ItemStatus, ReleaseRef, Components, Release, } from "./Api"
import { NewItemSpec, applyChanges, postNewItem, setEstimate, setReleases, updateItemStatus, } from "./api-actions";
import Wizard, { WizardScreen, WizardScreenProps } from "./Wizard";
import { AspectLabelDialog, AspectOption, AspectSelect, EstimateDialog, handleAspectSelection, ReleaseDialog, ReleaseOption, ReleaseSelect, StatusDialog, StatusSelect } from "./BacklogView";
import { doInSeries } from "./util";

export interface InitialProps {
    release:ReleaseRef|undefined,
    estimate:number|undefined,
    status:ItemStatus|undefined,
    aspects:AspectOption[]
}

const InitialPropsScreen = (props: { components: Components, onSelect: (position: InitialProps) => void }) => {
    const { components, onSelect } = props

    const [releaseRef, setRelease] = useState<ReleaseRef>()
    const [estimate, setEstimate] = useState<number>()
    const [status, setStatus] = useState<ItemStatus | undefined>(ItemStatus.todo)
    const [aspectOptions, setAspectOptions] = useState<AspectOption[]>([])

    const releaseOptions: Release[] = components.releases ?? []


    useEffect(() => {
        onSelect({
            release: releaseRef,
            estimate: estimate,
            status: status,
            aspects:aspectOptions,
        })
    }, [releaseRef, estimate, status, aspectOptions])

    return (
        <Stack spacing={2} style={{ margin: "20px" }}>

            <StatusSelect
                value={status}
                setValue={setStatus}
            />
            <ReleaseSelect
                value={releaseRef}
                options={releaseOptions}
                onChange={setRelease}
            />

            {/* <Alert color="info">
                An estimate is not a promise .. it's just your best idea of how big this is
            </Alert> */}
            <TextField
                margin="dense"
                id="Estimate"
                label="Initial Estimate"
                type="number"
                value={estimate}
                onChange={e => setEstimate(parseInt(e.target.value))}
                fullWidth
            />
            {components.aspects?.map(theAspect => {
                if (theAspect) {
                    const value = aspectOptions.find(a => {

                        console.log("a", a, theAspect)
                        return a?.aspect?.id === theAspect.id
                    })

                    return <AspectSelect
                        fullWidth={true}
                        aspect={theAspect}
                        value={value}
                        onSelect={selection => {
                            setAspectOptions(aspectOptions.filter(s => {
                                console.log("s", s, theAspect)
                                return  s?.aspect?.id != theAspect.id
                            }).concat([selection]))
                        }}
                    />
                }
            })}
        </Stack>
    )
}


const InitialPositionScreen = (props: { onSelect: (position: InitialBacklogPosition) => void }) => {
    const { onSelect } = props
    const [selection, setSelection] = useState<InitialBacklogPosition>(InitialBacklogPosition.next)

    console.log("Selection", selection)

    useEffect(() => {
        onSelect(selection)
    }, [selection])

    return (
        <FormControl>
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={selection}
                name="radio-buttons-group"
                onChange={(e, v) => { setSelection(v as InitialBacklogPosition) }}
            >
                <FormControlLabel value={InitialBacklogPosition.top} control={<Radio />} label="Top Priority (supercedes in-progress)" />
                <FormControlLabel value={InitialBacklogPosition.next} control={<Radio />} label="Next Priority (after in-progress)" />
                <FormControlLabel value={InitialBacklogPosition.bottom} control={<Radio />} label="End of Backlog" />
            </RadioGroup>
        </FormControl>
    )
}


const NameAndDescriptionScreen = (props: { existing: ItemComponent | undefined, config: PlanConfig, onChange: (spec: NewItemSpec) => void, onCancel: () => void }) => {
    const { existing, config, onChange } = props
    const [id, setId] = React.useState(existing?.id || "")
    const [title, setTitle] = React.useState(existing?.title || "")
    const [description, setDescription] = React.useState(existing?.description || "")
    const [error, setError] = React.useState<string>()

    useEffect(() => {
        onChange({
            id: id,
            title: title,
            description: description
        })
    }, [id, title, description])

    return <>

        {error && <Alert color="error">{error}</Alert>}
        <TextField
            autoFocus
            margin="dense"
            id="ID"
            label="ID"
            value={id}
            disabled={(existing?.id || config?.components?.manualIds == false) ? true : false}
            onChange={e => setId((e.target.value))}
            fullWidth
            variant="standard"
        />
        <TextField
            margin="dense"
            id="Title"
            label="Title"
            value={title}
            onChange={e => setTitle((e.target.value))}
            fullWidth
            variant="standard"
        />
        <TextField
            margin="dense"
            id="Description"
            label="Description"
            value={description}
            onChange={e => setDescription((e.target.value))}
            fullWidth
            multiline
            rows={8}
            variant="filled"
        />
    </>
}

export default (props: { planId: string, existing: ItemComponent | undefined, config: PlanConfig, components:Components, onComplete: () => void, onCancel: () => void }) => {
    const { planId, existing, config, components, onComplete, onCancel } = props

    const [firstScreenState, setFirstScreenState] = useState<NewItemSpec>()
    const [secondScreenState, setSecondScreenState] = useState<InitialBacklogPosition>()
    const [initialProps, setInitialProps] = useState<InitialProps>()

    
    const [error, setError] = React.useState<string>()

    const mainScreen: WizardScreen = {
        title: () => existing ? "Edit Item" : "New Item",
        constructor: (wProps) => <NameAndDescriptionScreen
            existing={existing}
            config={config}
            onChange={setFirstScreenState}
            onCancel={onCancel} />,
    }

    const newItemScreens: WizardScreen[] = [
        {
            title: () => "What's the priority?",
            constructor: (wProps) => <InitialPositionScreen onSelect={setSecondScreenState} />,
        },
        {
            title:()=>"Initial Properties",
            constructor:()=><InitialPropsScreen components={components} onSelect={setInitialProps}/>
        }
    ]

    const screens: WizardScreen[] = [mainScreen].concat(existing ? [] : newItemScreens)

    const doFinish = () => {
        console.log("Finish!", initialProps)
        console.log(firstScreenState)
        console.log(secondScreenState)

        if (!existing) {
            if (firstScreenState && secondScreenState && initialProps) {
                const newItems: NewItemComponent = {
                    position: secondScreenState,
                    item: {
                        id: "whatever",
                        title: firstScreenState.title,
                        description: firstScreenState.description,
                        descriptionMimeType: "text/plain",
                        url: undefined,
                        data: undefined
                    },
                }

                const setInitialProperties = (item:ItemComponent):Promise<any>=>{                    
                    return doInSeries([
                        ()=>(initialProps.release ? setReleases(planId, {[item.id]: initialProps.release}) : Promise.resolve("n/a")),
                        ()=>(initialProps.estimate !== undefined ? setEstimate(planId, item.id, initialProps.estimate, components) :  Promise.resolve("n/a")),
                        ()=>(initialProps.status ?  updateItemStatus(initialProps.status, item, planId) :  Promise.resolve("n/a"))
                        
                    ].concat(initialProps.aspects.map(av=> ()=>handleAspectSelection(av, item, components, planId))))
                }

                postNewItem(planId, newItems)
                    .then(p=>setInitialProperties(p.additions[0]))
                    .then(onComplete)
                    .catch(setError)

            }
        } else {
            if (firstScreenState) {
                const spec: NewItemSpec = firstScreenState
                applyChanges(spec, planId, existing)
                    .then(onComplete)
                    .catch(setError)
            }
        }
    }

    return (<Wizard
        title=""
        screens={screens}
        onComplete={doFinish}
        onCancel={onCancel}
    />)
}