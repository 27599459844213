import { Box, Breadcrumbs, Card, Typography } from "@mui/material"
import { Stack } from "@mui/system"
import { CSSProperties, ReactNode } from "react"
import { Link } from "react-router-dom"
import { PlanOverviewDto } from "./Api"
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { makeOrganizationUrl, makePlanUrl } from "."

export const breadcrumbStyle: CSSProperties = { color: '#222' }

export default (props: { title?: ReactNode, overview: PlanOverviewDto, controls: ReactNode, children?: ReactNode, actionsBar?: ReactNode, extraPathElements?:ReactNode[] }) => {
    const { title, overview, controls, children, actionsBar } = props
    const planId = overview.id

    const pathHere:ReactNode[] = [
        <Link style={breadcrumbStyle} to="/">
            orgs
        </Link>,
        <Link style={breadcrumbStyle} to={makeOrganizationUrl(overview.source ?? "??")}>
            {overview.source}
        </Link>,
        <Link style={breadcrumbStyle} to={makePlanUrl(planId)}>
            {overview.name}
        </Link>,
    ]
    const segments = pathHere.concat(props.extraPathElements ?? [])
    return (<>

        <Grid container style={{ margin: "20px" }}>
            <Grid xs={12} sm={6}>
                <Box>
                    <Breadcrumbs>
                        {segments.length > 0 && segments} hi
                    </Breadcrumbs>
                </Box>
            </Grid>

            {(actionsBar || controls) &&
                <Grid xs={12} sm={6}>
                    <Stack direction="row-reverse" spacing={2}>
                        {actionsBar}
                        {controls}
                    </Stack>
                </Grid>
            }
            <Grid xs={12}>
                <Box><Typography variant="h2">{title ?? overview.name}</Typography></Box>
            </Grid>
            <Grid xs={12}>

                {children}
            </Grid>
        </Grid>
    </>)
}