
import Markdown, { Options } from 'react-markdown'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { github } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import remarkGfm from 'remark-gfm';


export default (props: Options)=>{

    const allProps:Options = {
        ... props,
        remarkPlugins:[remarkGfm]
    }
    return <Markdown
    components={{
      code(props) {
        const {children, className, node, ...rest} = props
        const match = /language-(\w+)/.exec(className || '')
        return match ? (
          <SyntaxHighlighter
            PreTag="div"
            children={String(children).replace(/\n$/, '')}
            language={match[1]}
            style={github}
            wrapLongLines={true}
          />
        ) : (
          <code {...rest} className={className}>
            {children}
          </code>
        )
      }
    }}
    { ... allProps}
  />
}