import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material"
import { useState } from "react"

export interface WizardScreenProps {
    goNext:()=>void
    goBack:()=>void
}

export interface WizardScreen {
    title?:()=>string
    constructor:WizardScreenConstructor
}
export type WizardScreenConstructor = (p:WizardScreenProps)=>JSX.Element
export default (props:{title:string, screens:WizardScreen[], onComplete:()=>void, onCancel: () => void })=>{
    const {title, screens, onComplete, onCancel} = props
    const [screenIdx, setScreenIdx] = useState(0)

    const isLastScreen = screenIdx == (screens.length-1)
    const isFirstScreen = screenIdx == 0

    const goNext = ()=>{
        if(isLastScreen){
            onComplete()
        }else{
            setScreenIdx(screenIdx+1)
        }
    }
    const goBack = ()=>{setScreenIdx(screenIdx-1)}
    const screenProps:WizardScreenProps = {
        goNext: goNext,
        goBack: goBack,
    }

    const screenTHing = screens[screenIdx]
    const screen = screenTHing && screenTHing.constructor(screenProps)
    
    return (<>
    <Dialog open={true}>
        <DialogTitle>{screenTHing?.title ? screenTHing.title() : title}</DialogTitle>

        <DialogContent>
        {!screen && <Typography>All done</Typography>}
        {screen && screen}

        </DialogContent>
        <DialogActions>
            <Button onClick={onCancel}>Cancel</Button>
            {!isFirstScreen && <Button onClick={goBack} variant="outlined" >Back</Button>}
            <Button onClick={goNext} variant="contained" >{isLastScreen ? "Save" : "Next"}</Button>
        </DialogActions>
    </Dialog>
    </>)
}

