import { Link, Navigate, useParams } from "react-router-dom"
import PlanScreenLayout from "./PlanScreenLayout"
import { useEffect, useState } from "react"
import { getActualPlan, getComponents, getCurrentIteration, getPlanConfig, getPlanOverview } from "./api-actions"
import { Alert, Box, Card, CardContent, CircularProgress, MenuItem, Stack, Typography } from "@mui/material"
import { PlanSubmenu } from "./PlanSubmenu"
import PlanTimeSelectDialog from "./PlanTimeSelectDialog"
import Grid from "@mui/material/Unstable_Grid2/Grid2"
import { Components, PlanConfig, PlanOverviewDto } from "./Api"
import { makeReleaseBreakdownUrl } from "."
import PlanControlsBand from "./PlanControlsBand"


export default () => {
    const { planId } = useParams()

    const [isFetching, setFetching] = useState(true)
    const [plan, setPlan] = useState<PlanOverviewDto>()
    const [components, setComponents] = useState<Components>()
    const [error, setError] = useState<string>()
    const [overview, setOverview] = useState<PlanOverviewDto>()
    const [config, setConfig] = useState<PlanConfig>()
    const [threshold, setThreshold] = useState<number>()
    const [showTimeSelectDialog, setShowTimeSelectDialog] = useState(false)
    const versionId = "current"
    
    const refresh = () => {
        setFetching(true)

        if (planId) {
            Promise.all([
                getPlanOverview(planId).then(setOverview),
                getActualPlan(planId).then(setPlan),
                getComponents(planId, versionId).then(setComponents),
                getCurrentIteration(planId).then(i=>{
                    setThreshold(i.start)
                }),
                getPlanConfig(planId).then(setConfig)
            ]).catch(e => {
                console.log("Error:::", e)
                setError(e)
            }).finally(() => setFetching(false))
        }
    }

    useEffect(refresh, [planId])

    if(components?.aspects?.length == 1){
        return <Navigate to={makeReleaseBreakdownUrl(planId!!, components.aspects[0].id)}/>
    }else{

        return (<>
            {(planId && overview && components ) && <PlanScreenLayout
                overview={overview}
                controls={<>{isFetching && <CircularProgress/>}</>}
                actionsBar={<>
                    {isFetching && <CircularProgress />}
                    {config && <PlanControlsBand
                        planId={planId!!}
                        versionId={versionId}
                        isEditable={true}
                        plan={plan}
                        config={config}
                        components={components}
                        respondToUpdates={refresh} 
                        />}
                </>}
            >
                {error && <Alert color="error">{error}</Alert>}
                <PlanSubmenu planId={planId!!} components={components}/>
    
    
    
                <Box style={{ minHeight: "40px" }}>
                    {/* Spacer */}
                </Box>
                
                <Grid container spacing={3}>
                    {(components && (components.aspects || []).length === 0) && <Typography>There are no aspects</Typography>}
                    {(components?.aspects || []).map(a=>(
                        <Grid xs={12} sm={6} md={3}>
                        <Card>
                            <CardContent>
                                <Link style={{ color: '#222' }} to={makeReleaseBreakdownUrl(planId, a.id)}>By {a.name}</Link>
                            </CardContent>
                        </Card>
                        </Grid>
                    ))}
                </Grid>
            </PlanScreenLayout>
            }</>)
    }

}