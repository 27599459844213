
import React, { ReactNode } from 'react';
import { get, post, put } from './Http';
import { Button, Dialog, DialogActions, DialogContent, CircularProgress, Typography, Stack, DialogTitle, DialogContentText, Link, TextField, Checkbox, FormControlLabel, Box, Card, CardContent, CardActionArea } from '@mui/material';
import { ComponentsConfig, IterationsConfig, PlanSpecificationRequest } from './Api';
import { useJiraSettingsPanels } from './JiraPlanEditor';
import ErrorDialog from './ErrorDialog';
import SettingsPane from './SettingsPane';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { stringifyYearMonthDay, toYearMonthDay } from './YearMonthDay';
import { ApiCredentialsEditor, GeneralPlanSettingsEditorProps, IdentityEditor, useSettingsEditor } from './GeneralPlanSettingsEditor';
import { useSobSettingsPanels } from './SobPlanEditor';


const defaultComponentsConfig:ComponentsConfig = {
  iterations: undefined,
  itemsAreEditable: undefined,
  manualIds: false,
}

export const CalculatedIterationsEditor = (props:{value:IterationsConfig, onChange:(v:IterationsConfig)=>void})=>{
  const {value:iterationsConfig, onChange} = props
  return (<>
      <DatePicker label="Start Date"
         defaultValue={moment(stringifyYearMonthDay(iterationsConfig.firstIterationDate))} 
         onChange={e=>{
          console.log(e)
          if(e){
            onChange({... iterationsConfig, firstIterationDate:toYearMonthDay(e)})
          }
         }}/>
      <TextField label="Length in Days" 
          type='number'
          value={iterationsConfig.iterationLengthInDays }
          onChange={e=> {
            const v = e.target.value
            const n = parseInt(v)
            console.log("n value is ", v, typeof v, n)
            onChange({... iterationsConfig, iterationLengthInDays:parseInt(v)})
          }}/>
  </>)
}

export const useComponentsPlanPanels = (props: {value:PlanSpecificationRequest, onChange:(v:PlanSpecificationRequest)=>void}):Record<string, (p:GeneralPlanSettingsEditorProps)=>ReactNode> => {

  const {value, onChange} = props
  const defaultDate = {year:2020, month:1, day:1}
  const components = value?.config.components || defaultComponentsConfig
  const iterationsConfig:IterationsConfig = value?.config.components?.iterations ?? {firstIterationDate:defaultDate, iterationLengthInDays:14}

  const withUpdatedConfig = (i:IterationsConfig):PlanSpecificationRequest => {
    const uValue = {... value}

    
    uValue.config.components =  {... components, iterations:i}

    console.log("uValue is", uValue)

    return uValue
  }
  
  const manualIds = components.manualIds ?? true

  console.log("Rendering manualIds", manualIds, components.manualIds)
  
  const toggleManualIds = ()=>{
    const uValue = {... value}
    const n = !manualIds
    console.log("Changing from ", manualIds, "to", n)
    uValue.config.components = {
      ... components, 
      manualIds: n
    }
    console.log("new value is ", uValue)
    onChange(uValue)
  }

  const toggleEditable = ()=>{
    const uValue = {... value}
    uValue.config.components = {... components, itemsAreEditable: (!components.itemsAreEditable)}
    onChange(uValue)
  }

  return {

    "Identity":({value, onChange})=><IdentityEditor value={value} onChange={onChange}/>,
    "Data":({value, onChange})=><SettingsPane name="Data">
        <FormControlLabel
              value="start"
              control={
                <Checkbox
                  checked={components.itemsAreEditable}
                  onChange={toggleEditable}
                  inputProps={{ 'aria-label': 'controlled' }}
                />}
              label="Editable"
              labelPlacement="end"
            />
            <FormControlLabel
              value="start"
              control={
                <Checkbox
                  checked={!manualIds}
                  onChange={toggleManualIds}
                  inputProps={{ 'aria-label': 'controlled' }}
                />}
              label="Automatically Create IDs"
              labelPlacement="end"
            />
    </SettingsPane>,

  "Iterations":({value, onChange})=><SettingsPane name="Iterations">
      <CalculatedIterationsEditor value={iterationsConfig} onChange={v=>onChange(withUpdatedConfig(v))}/>
    </SettingsPane>,

  "API Publishing":({value, onChange})=><ApiCredentialsEditor value={value} onChange={onChange}/>
  }
}

interface PlaneEditDialogProps {
  currentValue: PlanSpecificationRequest
  existing: boolean
  handleSuccess: () => void
  handleClose: () => void
}
export default (props: PlaneEditDialogProps) => {
  let { currentValue, existing, handleClose, handleSuccess } = props
  let { config } = currentValue

  console.log("existing", JSON.stringify(currentValue))

  let [editedPlan, setEditedPlan] = React.useState(currentValue)
  let [isWorking, setWorking] = React.useState(false)
  let [error, setError] = React.useState<string>()


  let createNew = (stuff: PlanSpecificationRequest) => {
    setWorking(true)
    let json = JSON.stringify(stuff, null, 4)
    console.log(json)

    post(json, "/plans",
      (result) => {
        handleSuccess()
      },
      (error) => {
        setWorking(false)
        console.log("Error:", error)
        setError("ERROR (" + error.statusText + "): " + error.responseText)
      })
  }

  let updateExisting = (stuff: PlanSpecificationRequest) => {
    setWorking(true)
    let json = JSON.stringify(stuff, null, 4)
    console.log(json)
    put(json, "/plans/" + stuff.name,
      (result) => {
        handleSuccess()
      },
      (error) => {
        setWorking(false)
        console.log("Error:", error)
        setError("ERROR (" + error.statusText + "): " + error.responseText)
      })
  }

  const onComplete = existing ? updateExisting : createNew
  const actionName = existing ? "Update" : "Create"

  let typeName;
  let typeDescription;
  let editor:ReactNode;

  let onPlanChange = (newValue: PlanSpecificationRequest) => {
    console.log("GOT PLAN UPDATE:", JSON.stringify(newValue, null, 4))
    setEditedPlan(newValue)
  }

  const doSave = ()=>{
    onComplete(editedPlan)
  }
  let buttons:ReactNode = (
    <>
        <Button onClick={handleClose}>Cancel</Button>
        <Button variant="contained" onClick={doSave}>{actionName}</Button>
      </>
  )
  if (config.components){
    typeName = "Basic"
    typeDescription = <>Basic Plan - All Components are Stored here</>
    const panels = useComponentsPlanPanels({value:editedPlan, onChange:onPlanChange})

    const wizard = useSettingsEditor({
      panels:panels, 
      value:editedPlan, 
      onChange:onPlanChange,
      onSave:doSave,
    })
    editor = wizard.content
    buttons = wizard.buttons
  }else if (config.jira) {
    typeName = "JIRA"
    typeDescription = <>Atlassian <Link href="https://www.atlassian.com/software/jira">JIRA</Link> is a popular issue-tracking tool.  This integration derives a team plan from an <Link href="https://www.atlassian.com/software/jira/agile">JIRA Agile</Link> "board". </>

    const panels = useJiraSettingsPanels({value:editedPlan})
    const wizard = useSettingsEditor({
        panels:panels, 
        value:editedPlan, 
        onChange:onPlanChange,
        onSave:doSave,
    })
    editor = wizard.content
    buttons = wizard.buttons
  } else if (config.storiesOnBoard) {
    typeName = "StoriesOnBoard"
    typeDescription = <><Link href="https://storiesonboard.com/">StoriesOnBoard</Link> is a story-mapping tool.  This integration derives a team plan from a StoriesOnBoard "story map". </>
   
    const panels = useSobSettingsPanels({
      value:editedPlan, 
      onChange:onPlanChange,
    })

    const wizard = useSettingsEditor({
      panels:panels, 
      value:editedPlan, 
      onChange:onPlanChange,
      onSave:doSave,
    })
    editor = wizard.content
    buttons = wizard.buttons
  }

  return <Dialog onClose={handleClose} open={true} PaperProps={{
    sx: {
      minHeight: 600
    }
  }}>
  <DialogTitle>{typeName}-Backed Plan</DialogTitle>
    <DialogContent>
      {error && <ErrorDialog error={error} closeDialog={()=>setError(undefined)}/>}
          <DialogContentText>{typeDescription}</DialogContentText>
          <Box style={{marginTop:"30px"}}>
            {editor}
          </Box>
    </DialogContent>
    <DialogActions>
      {isWorking ? <CircularProgress /> : buttons}
    </DialogActions>
  </Dialog>

}