import { Box, Button, Typography } from "@mui/material"
import { Stack } from "@mui/system"
import React, { CSSProperties, ReactNode } from "react"
import DragHandleIcon from '@mui/icons-material/DragHandle';

const commonLook:CSSProperties = { 
    opacity:1,
    background:"white"
}

interface DragData<T, > {
    item:T,
    previous:T|undefined
}

interface DraggableProps<T>{
    key:any, 
    id:any, 
    children?:ReactNode|undefined, 
    item:T, 
    previous:T|undefined,
}

// Using the mousedown trick for a drag handle: https://stackoverflow.com/questions/26283661/drag-drop-with-handle
export const ThumbDraggable = <T, >(props:DraggableProps<T>)=>{
    const {children, item, previous} = props
    const [draggable, setDraggable] = React.useState(false)
    const data:DragData<T> = {previous:previous, item:item}
    return (<div 
        id={props.id} 
        draggable={draggable} 
        onDragEnd={()=>setDraggable(false)}
        onDragStart={e=>{e.dataTransfer.setData("application/json", JSON.stringify(data))}}
        style={commonLook}>
            <div onMouseDown={()=>setDraggable(true)}
            onMouseUp={()=>setDraggable(false)}><DragHandleIcon/></div>
            <div draggable="false">{children}</div>
        </div>)
}

const Droppable = <T, >(props:{previous:T|undefined, style?:CSSProperties, onDrop:(item:T, after:T|undefined)=>void})=>{
    const {previous, style, onDrop} = props
    const [hasHover, setHover] = React.useState(false)

    // console.log("I have hover? ", hasHover)

    return (<div
            onDragEnter={e=>{setHover(true); e.preventDefault()}}
            onDragOver={e=>e.preventDefault()}
            onDragLeave={e=>setHover(false)}
            onDragEnd={e=>setHover(false)}
            onDrop={e=>{
                const text = e.dataTransfer.getData("application/json")
                console.log("I was dropped-on?", text)
                const data:DragData<T> = JSON.parse(text)
                onDrop(data.item,  previous)
                setHover(false)
            }}
            style={ {
                padding:"10px",
                opacity:1,
                background: (hasHover ? "rgb(239 239 239)":"white"),
                ... style
            }}
            >
    </div>)
}

interface ReorderableListProps<T> {
    items:T[], 
    editable:boolean, 
    useThumbs:boolean,
    insertAfter:(item:T, after:T|undefined)=>void, 
    renderItem:(item:T)=>ReactNode,
}

export const ReorderableList = <T,>(props:ReorderableListProps<T>)=>{
    const {items, editable, useThumbs, insertAfter, renderItem} = props

    const handleTopDrop = (i:T, a:T|undefined)=> {
        editable && insertAfter(i, a)
    }

    if(items.length==0){
        return (
            <Droppable 
                previous={undefined} 
                style={items.length == 0 ? {height:"100%"} : {}}
                onDrop={handleTopDrop}/>)
    }else{
        
        return (
            <Stack style={{marginLeft:"10px", marginRight:"10px"}}>
                <Droppable 
                    previous={undefined} 
                    onDrop={handleTopDrop}/>

                {items.map((x, idx)=>{

                    let draggableProps:DraggableProps<T> = {key:idx, id:idx.toString(), item:x, previous:(idx==0 ? undefined : items[idx-1])}

                    return (<>
                        {!editable && <Box key={idx} style={{marginTop:"28px", marginBottom:"19px"}}>
                            {renderItem(x)}
                        </Box>}
                        {editable && <>
                            {useThumbs ? <ThumbDraggable {... draggableProps}>{renderItem(x)}</ThumbDraggable>:<Draggable {... draggableProps}>{renderItem(x)}</Draggable>}
                            <Droppable previous={x} onDrop={(i:T, a)=> editable && insertAfter(i, a)}/>
                        </>}
                    </>)
                    }
                )}
            </Stack>)
    }

}


export const Draggable = <T, >(props:DraggableProps<T>)=>{
    const {children, item, previous} = props
    const data:DragData<T> = {previous:previous, item:item}
    return (<div 
        id={props.id} 
        draggable={true} 
        onDragEnd={()=>{}}
        onDragStart={e=>{e.dataTransfer.setData("application/json", JSON.stringify(data))}}
        style={{}}>
            {children}
        </div>)
}