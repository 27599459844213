import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, } from "@mui/material"
import { useState } from "react"
import { AspectValue, } from "./Api"
import _ from "underscore"
import { addAspectValue, updateAspectValue } from "./api-actions";

export default (props: {planId:string, aspectId:string, value:AspectValue|undefined, onUpdate:(v:AspectValue)=>void, onCancel: () => void }) => {
    const {planId, aspectId, value, onUpdate, onCancel } = props
    const [name, setName] = useState<string>(value?.name ?? "")
    const [error, setError] = useState<string>()

    console.log("Editing", value, name)
    console.log("name", name)

    const handleSave = ()=>{
        setError(undefined)
        if(value){
            updateAspectValue(planId, aspectId, {... value, name:name})
                .then(onUpdate)
                .catch(setError)

        }else{
            addAspectValue(planId, aspectId, {id:"whatever", name:name})
            .then(onUpdate)
            .catch(setError)

        }
    }

    return (<Dialog open={true} fullWidth={true} fullScreen={false}>
        <DialogTitle>Aspect Value</DialogTitle>

        <DialogContent style={{paddingTop:"20px"}}>
            {error && <Alert color="error">{error}</Alert>}
            <TextField label="value" value={name} onChange={e=>setName(e.target.value)}/>
        </DialogContent>
        <DialogActions>
            <Button onClick={onCancel}>Cancel</Button>
            <Button onClick={handleSave}>Save</Button>
        </DialogActions>
    </Dialog>)
}
