import React, { ReactNode, useEffect, useState } from 'react';
import { Button, TextField, FormControl, Divider, Stack, Card, CardContent, CardActions, Typography, FormHelperText, Grid, FormControlLabel, Checkbox, MenuItem, Dialog, DialogTitle } from '@mui/material';
import SettingsPane from './SettingsPane';
import { IterationsConfig, JiraCredentials, JiraSourceSettings, PlanSpecificationRequest } from './Api';
import { CalculatedIterationsEditor } from './PlanEditDialog';
import moment, { Moment } from 'moment';
import LabeledSelect from './LabeledSelect';
import { ApiCredentialsEditor, GeneralPlanSettingsEditorProps, IdentityEditor} from './GeneralPlanSettingsEditor';
import { DateTimeField, DateTimePicker } from '@mui/x-date-pickers';

interface JiraSettingsEditorProps {
    value: PlanSpecificationRequest
}

const defaultJiraConfig:JiraSourceSettings = {
    boardId: "",
    url: "",
    storyPointsField: "",
    auth: {
        "username": "",
        "password": ""
    },
    iterations:undefined,
    fixedStorySize:undefined,
}

enum SizingMode {
    fixed = "fixed", 
    variable = "variable", 
}
export const JiraEstimatesSettingsPanel = ({value, onChange}:GeneralPlanSettingsEditorProps) => {
    const jiraSettings: JiraSourceSettings = value.config.jira ?? defaultJiraConfig

    const [sizingMode, setSizingMode] = useState<SizingMode>(jiraSettings.fixedStorySize ? SizingMode.fixed : SizingMode.variable)
    const [fixedStorySize, setFixedStorySize] = useState(jiraSettings.fixedStorySize ?? 5)
    const [storyPointsField, setStoryPointsField] = useState(jiraSettings.storyPointsField)

    const fireCurrentState = ()=>{
        let update:JiraSourceSettings
        switch(sizingMode){
            case SizingMode.fixed: 
            update = { ...jiraSettings, fixedStorySize: fixedStorySize}
            break;
            case SizingMode.variable: 
            update = { ...jiraSettings, storyPointsField:storyPointsField, fixedStorySize: undefined}
            break;
        }
        onChange({
            ...value,
            config: {
                ...value.config,
                jira: update
            }
        })
    }

    useEffect(fireCurrentState, [sizingMode, fixedStorySize, storyPointsField])
    return (<>
        <LabeledSelect 
                labelId ="iteration-boundaries-select" 
                label="Mode" 
                value={sizingMode} onChange={e => setSizingMode(e.target.value as SizingMode)}>
                    {Object.keys(SizingMode).map(key=>{
                        return (<MenuItem value={key}>{key}</MenuItem>)
                    })}
        </LabeledSelect>
        {(sizingMode == SizingMode.fixed) && <TextField label="Default Story Size" type="number" variant="standard" fullWidth value={fixedStorySize} onChange={e => setFixedStorySize(parseInt(e.target.value))} />}

        {(sizingMode == SizingMode.variable) && <TextField label="Story Points Field" variant="standard" fullWidth value={storyPointsField} onChange={e => setStoryPointsField(e.target.value)} />}
    </>)
}

export const useJiraSettingsPanels = (props: JiraSettingsEditorProps):Record<string, (p:GeneralPlanSettingsEditorProps)=>ReactNode> => {
    const { value} = props

    const jiraSettings: JiraSourceSettings = value.config.jira ?? defaultJiraConfig

    const [showIterations, setShowIterations] = useState(jiraSettings.iterations ? true : false)
    const [iterations, setIterations] = useState<IterationsConfig>(jiraSettings.iterations ?? {
        firstIterationDate:{year:2020, month:1, day:1}, 
        iterationLengthInDays:14,
    })

    const url = jiraSettings?.url
    const storyPointsField = jiraSettings?.storyPointsField
    const boardId = jiraSettings?.boardId
    const auth = jiraSettings?.auth
    const username = auth?.username
    const password = auth?.password


    console.log("JiraSettingsEditor value is", JSON.stringify(value, null, 4))


    const fireJiraChange = (update: JiraSourceSettings, onChange: (newValue: PlanSpecificationRequest) => void) => {
        onChange({
            ...value,
            config: {
                ...value.config,
                jira: update
            }
        })
    }

    const fireJiraCredentialsChange = (update: JiraCredentials, onChange: (newValue: PlanSpecificationRequest) => void) => {
        fireJiraChange({
            ...jiraSettings,
            auth: update
        }, onChange)
    }

    const handleIterationsModeSwitch = (mode:string, onChange: (newValue: PlanSpecificationRequest) => void)=>{
        if(mode == "calculated"){
            fireJiraChange({ ...jiraSettings, iterations: iterations}, onChange)
            setShowIterations(true)
        }else{
            fireJiraChange({ ...jiraSettings, iterations: undefined}, onChange)
            setShowIterations(false)
        }
    }

    return {
        "Identity":({value, onChange})=><IdentityEditor value={value} onChange={onChange}/>,
        "JIRA":({value, onChange})=>(<SettingsPane name="JIRA Board" description="These settings control how Plan connects to your JIRA">
            <TextField label="JIRA Account URL" variant="standard" fullWidth value={url} placeholder="https://yourorg.atlassian.net" onChange={e => fireJiraChange({ ...jiraSettings, url: e.target.value }, onChange)} />
            <FormHelperText>e.g. https://[YOUR_ORG].atlassian.net/</FormHelperText>
            <TextField label="Board ID" type="number" variant="standard" fullWidth value={boardId} onChange={e => fireJiraChange({ ...jiraSettings, boardId: e.target.value }, onChange)} />
            <TextField label="Story Points Field" variant="standard" fullWidth value={storyPointsField} onChange={e => fireJiraChange({ ...jiraSettings, storyPointsField: e.target.value }, onChange)} />
            <TextField label="User" variant="standard" fullWidth value={username} placeholder="pat@yourorg.com" onChange={e => fireJiraCredentialsChange({ ...auth, username: e.target.value }, onChange)} />
            <FormHelperText>The username/email you use to log-on to atlassian</FormHelperText>
            <TextField label="API Token" variant="standard" type="password" fullWidth value={password} onChange={e => fireJiraCredentialsChange({ ...auth, password: e.target.value }, onChange)} />
            <FormHelperText>Get your API tokens from atlassian <a href="https://id.atlassian.com/manage-profile/security/api-tokens" target="_blank">here</a></FormHelperText>
        </SettingsPane>),
        "Iterations":({value, onChange})=>(<SettingsPane name="Iteration Boundaries">
            <LabeledSelect 
                labelId ="iteration-boundaries-select" 
                label="Mode" 
                value={showIterations ? "calculated" : "jira"} onChange={e => handleIterationsModeSwitch(e.target.value, onChange)}>
                    <MenuItem value="jira">Use JIRA Iterations (only works for 'scrum'-style boards)</MenuItem>
                    <MenuItem value="calculated">Calculate From Calendar (neeeded for 'kanbaan'-style boards)</MenuItem>
            </LabeledSelect>
            {showIterations && <CalculatedIterationsEditor value={iterations} onChange={v=>{
                setIterations(v)
                fireJiraChange({ ...jiraSettings, iterations: v}, onChange)
            }}/>}
        </SettingsPane>),
        "Estimation":(props)=><JiraEstimatesSettingsPanel {... props}/>,
        "Advanced":({value, onChange})=>(
            <ApiCredentialsEditor value={value} onChange={onChange}/>
        )
    }
}

