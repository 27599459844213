import './App.css';
import logo from './logo.png'
import React, { CSSProperties, useEffect, useState } from 'react';
import { get, post, put } from './Http';
import { CircularProgress, IconButton, Fab, Stack, Box, Card, CardHeader, CardContent, Typography, Tooltip, CardActionArea, CardActions } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddIcon from '@mui/icons-material/Add';
import ExtensionIcon from '@mui/icons-material/Extension';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import DataObjectIcon from '@mui/icons-material/DataObject';
import CloudIcon from '@mui/icons-material/Cloud';
import { DataGrid, GridColumns, GridRenderCellParams } from '@mui/x-data-grid';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import PlanEditDialog from './PlanEditDialog';
import { PlanOverviewDto, PlanSpecificationRequest } from './Api';

export interface Focus {
    existing: boolean,
    plan: PlanSpecificationRequest
}
  
export default (props: { setFocus: (f: Focus) => void, style?: CSSProperties }) => {
    const { setFocus, style } = props
    const [anchorEl, setAnchorEl] = React.useState<HTMLAnchorElement>()
    const open = Boolean(anchorEl)
  
    const handleClose = () => {
      setAnchorEl(undefined);
    };
  
  
  
    const defaultbasicCredentials = {
      user: "",
      secret: ""
    }
  
    const addBasic = () => {
      handleClose()
      setFocus({
        existing: false,
        plan: {
          name: "",
          source: "",
          config: {
            basicCredentials: defaultbasicCredentials,
            storiesOnBoard: undefined,
            jira: undefined,
            components: {
              itemsAreEditable: false,
              iterations: undefined,
              manualIds: false,
            },
            notBefore: undefined,
          }
        }
      })
    }
  
    const addJira = () => {
      handleClose()
      setFocus({
        existing: false,
        plan: {
          name: "",
          source: "",
          config: {
            basicCredentials: defaultbasicCredentials,
            storiesOnBoard: undefined,
            jira: {
              boardId: "",
              url: "",
              storyPointsField: "",
              auth: {
                username: "",
                password: ""
              },
              iterations: undefined,
              fixedStorySize: undefined,
            },
            notBefore: undefined,
          }
        }
      })
    }
  
  
    const addSob = () => {
      handleClose()
      setFocus({
        existing: false,
        plan: {
          name: "",
          source: "",
          config: {
            jira: undefined,
            storiesOnBoard: {
              username: "",
              password: "",
              orgName: "",
              boardName: "",
              storyMapId: "",
              firstIterationDate: "2020-01-06",
              iterationLengthInDays: 14
            },
            basicCredentials: defaultbasicCredentials,
            notBefore: undefined,
          }
        }
      })
    }
  
    let handleAddPlan: React.MouseEventHandler<HTMLAnchorElement> = (event) => {
      console.log("Add Plan")
      setAnchorEl(event.currentTarget);
    }
  
  
    const fabStyle: CSSProperties = { position: "fixed", top: "10", right: "20px", margin: "20px" }
    return (<>
  
      <Fab
        href=""
        onClick={handleAddPlan}
        color='primary'
        style={{ float: "right", ...fabStyle, ...style }}><AddIcon /></Fab>
  
      <Menu
        id="new-plan-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>
        <MenuItem onClick={addBasic}>Basic</MenuItem>
        <MenuItem onClick={addJira}>JIRA</MenuItem>
        <MenuItem onClick={addSob}>Stories-On-Board</MenuItem>
      </Menu>
    </>)
  }