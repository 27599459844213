import { Aspect, AspectRef, AspectValue, Components, ItemComponent, ItemsPatch, ItemStatus, NewItemComponent, PlanConfig, PlanOverviewDto, Release, ReleaseRef } from "./Api"
import { get, post, put, patch, send } from "./Http"
import _ from "underscore"

export interface VersionListEntry {
    id:string,
    whenChanged:number,
    userId:string|undefined,
}
export const getVersions = (planId:string):Promise<VersionListEntry[]>=>{
    return new Promise((resolveSuccess, resolveFail)=>{
        send("GET", "", `/plans/${planId}/versions`,
            (result) => {
                resolveSuccess(JSON.parse(result))
            },
            (xhr) => resolveFail(`${xhr.statusText}: ${xhr.responseText}`))
    })
}

export const fetchPlans = ():Promise<PlanOverviewDto[]> => {
    return new Promise((resolveSuccess, resolveFailed)=>{
      get("/plans", (result: PlanOverviewDto[]) => {
        resolveSuccess(result)
      })
    })
  }
  
export interface Iteration{
    start:number
    end:number
}
export const getIterations = (planId:string):Promise<Iteration[]> => {
    return new Promise((resolveSuccess, resolveFail)=>{
        send("GET", "", `/api/plans/${planId}/iterations`,
            (result) => {
                resolveSuccess(JSON.parse(result))
            },
            (xhr) => resolveFail(`${xhr.statusText}: ${xhr.responseText}`))
    })
}
export const getCurrentIteration = (planId:string):Promise<Iteration> => {
    return new Promise((resolveSuccess, resolveFail)=>{
        send("GET", "", `/api/plans/${planId}/iterations/current`,
            (result) => {
                resolveSuccess(JSON.parse(result))
            },
            (xhr) => resolveFail(`${xhr.statusText}: ${xhr.responseText}`))
    })
}
export const getComponents = (planId:string, versionSpec:string):Promise<Components> => {
    return new Promise((resolveSuccess, resolveFail)=>{
        send("GET", "", `/api/plans/${planId}/${versionSpec}/components`,
            (result) => {
                resolveSuccess(JSON.parse(result))
            },
            (xhr) => resolveFail(`${xhr.statusText}: ${xhr.responseText}`))
    })
}
export const getPlanOverview = (planId:string):Promise<PlanOverviewDto> => {
    return new Promise((resolveSuccess, resolveFail)=>{
        send("GET", "", `/plans/${planId}/overview`,
            (result) => {
                resolveSuccess(JSON.parse(result))
            },
            (xhr) => resolveFail(`${xhr.statusText}: ${xhr.responseText}`))
    })
}

export const getPlanConfig = (planId:string):Promise<PlanConfig> => {
    return new Promise((resolveSuccess, resolveFail)=>{
        send("GET", "", `/plans/${planId}`,
            (result) => {
                resolveSuccess(JSON.parse(result))
            },
            (xhr) => resolveFail(`${xhr.statusText}: ${xhr.responseText}`))
    })
}


export const getActualPlan = (planId:string):Promise<PlanOverviewDto> => {
    return new Promise((resolveSuccess, resolveFail)=>{
        fetchPlans().then(plans => {
            plans.forEach(p=>{
             const plan = p
 
             if(plan?.id == planId){
                resolveSuccess(plan)
             }
            }) 
         })
    })
}

export const postNewItem = (planId: string, items:NewItemComponent):Promise<ItemsPatch> => {
    return new Promise((resolveSuccess, resolveFailed)=>{
        post(
            JSON.stringify(items),
            `/api/plans/${planId}/components/items`,
            (txt) => resolveSuccess(JSON.parse(txt)),
            resolveFailed,
        )
    })
}

export const sendItemsPatch = (planId: string, p: ItemsPatch, onSuccess: () => void, onError?: (err: XMLHttpRequest) => void) => {
    patch(JSON.stringify(p),
        `/api/plans/${planId}/components/items`,
        onSuccess,
        onError
    )
}


export interface NewItemSpec {
    id: string
    title: string
    description: string
}


export const applyChanges = (item: NewItemSpec, planId:string, existing: ItemComponent | undefined):Promise<string> => {

    return new Promise((resolveSuccess, resolveFail)=>{

        const patchItems = (p: ItemsPatch) => {
            sendItemsPatch(planId, p,
                () => {
                    resolveSuccess(item.id)
                },
                (xhr) => resolveFail(`${xhr.statusText}: ${xhr.responseText}`)
            )
        }

        const i: ItemComponent = {
            id: item.id,
            title: item.title,
            description: item.description,
            descriptionMimeType: "text/plain",
            url: undefined,
            data: undefined
        }
        if (existing) {
            console.log("Need to update ", item)
            patchItems({
                additions: [],
                updates: [i],
                deletions: []
            })
        } else {
            console.log("Need to add ", item)
            patchItems({
                additions: [i],
                updates: [],
                deletions: []
            })
        }
    })
}

export const addAspectValue = (planId:string, aspectId:string, v:AspectValue):Promise<AspectValue>=>{
    return new Promise((resolveSuccess, resolveFailed)=>{
        post(JSON.stringify(v),
            `/api/plans/${planId}/components/aspects/${aspectId}/values`,
            text=>resolveSuccess(JSON.parse(text) as AspectValue),
            errorHandler(resolveFailed)
        )
    })
}

const errorHandler = (resolveFailed: (reason?: any) => void):((xhr:XMLHttpRequest)=>void)=>{
    return (xhr)=>{
        resolveFailed(`Server said ${xhr.status} ${xhr.statusText}: ${xhr.responseText}`)
    }
}

export const updateAspectValue = (planId:string, aspectId:string, v:AspectValue):Promise<AspectValue>=>{
    return new Promise((resolveSuccess, resolveFailed)=>{
        put(JSON.stringify(v),
            `/api/plans/${planId}/components/aspects/${aspectId}/values/${v.id}`,
            text=>resolveSuccess(JSON.parse(text) as AspectValue),
            errorHandler(resolveFailed)
        )
    })
}

export const deleteAspectValue = (planId:string, aspectId:string, valueId:string):Promise<string>=>{
    return new Promise((resolveSuccess, resolveFailed)=>{
        send("delete",
            null,
            `/api/plans/${planId}/components/aspects/${aspectId}/values/${valueId}`,
            resolveSuccess,
            errorHandler(resolveFailed)
        )
    })
}


export const getAspect = (planId:string, aspectId:string):Promise<Aspect>=>{
    return new Promise((resolveSuccess, resolveFailed)=>{
        send(
            "GET",
            null,
            `/api/plans/${planId}/components/aspects/${aspectId}`,
            text=>resolveSuccess(JSON.parse(text) as Aspect),
            errorHandler(resolveFailed)
        )
    })
}

export const getAspectValues = (planId:string, aspectId:string):Promise<AspectValue[]>=>{
    return new Promise((resolveSuccess, resolveFailed)=>{
        send(
            "GET",
            null,
            `/api/plans/${planId}/components/aspects/${aspectId}/values`,
            text=>resolveSuccess(JSON.parse(text) as AspectValue[]),
            errorHandler(resolveFailed)
        )
    })
}

export const setItemOrder = (r:{ id: string, after: string|undefined }, planId:string):Promise<any>=>{
    return new Promise((resolveSucces, resolveFailed)=>{
        patch(JSON.stringify(r),
            `/api/plans/${planId}/components/order`,
            resolveSucces,
            resolveFailed
        )
    })
}

export const updateItemStatus = (status: ItemStatus, item: ItemComponent, planId:string):Promise<any> => {

    return new Promise((resolveSuccess, resolveFailed)=>{
        
        const payload: Record<string, ItemStatus> = {}
        payload[item.id] = status

        patch(JSON.stringify(payload),
            `/api/plans/${planId}/components/statuses`,
            resolveSuccess,
            resolveFailed
        )
    })
}


export const setTitles = (planId:string, titles: Record<string, string>):Promise<any>=>{
    return new Promise((resolveSuccess, resolveFailed)=>{
        patch(JSON.stringify(titles),
            `/api/plans/${planId}/components/titles`,
            resolveSuccess,
            resolveFailed,
        )
    })
}

export const setReleases = (planId:string, assignments: Record<string, ReleaseRef>):Promise<any>=>{
    return new Promise((resolveSuccess, resolveFailed)=>{

        patch(JSON.stringify(assignments),
            `/api/plans/${planId}/components/release-assignments`,
            resolveSuccess,
            resolveFailed,
        )
    })
}

export const createNewRelease = (planId:string, release:Release):Promise<Release>=>{
    return new Promise((resolveSuccess, resolveFailed)=>{
        send(
            "POST",
            JSON.stringify(release),
            `/api/plans/${planId}/components/releases`,
            text=>resolveSuccess(JSON.parse(text) as Release),
            errorHandler(resolveFailed)
        )
    })
}


export const updateRelease = (planId:string, release:Release):Promise<Release>=>{
    return new Promise((resolveSuccess, resolveFailed)=>{
        send(
            "PUT",
            JSON.stringify(release),
            `/api/plans/${planId}/components/releases/${release.id}`,
            text=>resolveSuccess(JSON.parse(text) as Release),
            errorHandler(resolveFailed)
        )
    })
}

export const patchAspectAssignments = (planId:string, updatedAssociations:Record<string, AspectRef[]>):Promise<any>=>{

    return new Promise((resolveSuccess, resolveFailed)=>{
        patch(JSON.stringify(updatedAssociations),
            `/api/plans/${planId}/components/aspect-assignments`,
            resolveSuccess,
            errorHandler(resolveFailed)
        )
    })
}

export const setEstimate =(planId:string, itemId:string, estimate:number, components:Components):Promise<any>=>{

    const updatedEstimates = { ...components.estimates }
    updatedEstimates[itemId] = estimate
    
    return new Promise((resolveSuccess, resolveFailed)=>{
        patch(JSON.stringify(updatedEstimates),
            `/api/plans/${planId}/components/estimates`,
            resolveSuccess,
            errorHandler(resolveFailed)
        )
    })
}