import { Stack, Typography } from "@mui/material"
import { Link } from "react-router-dom"
import { joinReact } from "./BacklogView"
import { Components } from "./Api"
import { ReactNode } from "react"
import { makePrioritizationUrl, makeReleaseBreakdownUrl, makeReleasesUrl, makeWorkflowUrl } from "."


const MenuItem = (props:{children:ReactNode, to:string})=>{
    const {children, to:path} = props

    const active = window.location.pathname == path

    console.log("is " + children + " active? ", active, window.location.pathname, "vs", path)

    if(active){
        return <Link style={{ color: '#222', fontWeight:"bold" }} to={path}>{children}</Link>
    }else{
        return <Link style={{ color: '#222' }} to={path}>{children}</Link>
    }
}

export const PlanSubmenu = (props:{planId:string, components:Components|undefined})=>{
    const {planId, components} = props

    return (<>
        <Stack direction="row" >
            {joinReact(
                [
                    <MenuItem to={makeWorkflowUrl(planId)}>Work Flow</MenuItem>,
                    <MenuItem to={makePrioritizationUrl(planId)}>Prioritization</MenuItem>, 
                    <MenuItem to={makeReleasesUrl(planId)}>Releases</MenuItem>,
                ], 
                <>&nbsp;&nbsp;|&nbsp;&nbsp;</>)}
        </Stack>
    </>)
}
