import React from "react";
import { AppBar, Dialog, DialogTitle, DialogContent, DialogContentText, IconButton, Slide, Toolbar, Typography } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import CloseIcon from '@mui/icons-material/Close';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface ErrorDialogProps {
    error: string
    closeDialog: () => void
}
export default (props: ErrorDialogProps) => {
    const { error, closeDialog } = props
    return <Dialog fullScreen onClose={closeDialog}
        TransitionComponent={Transition}
        open={true}>
        <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={closeDialog}
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                    Oops!  That didn't work.  See below and try again?
                </Typography>
            </Toolbar>
        </AppBar>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
            <DialogContentText>
                <div style={{ whiteSpace: "pre" }}>{error}</div>
            </DialogContentText>
        </DialogContent>
    </Dialog>
}
