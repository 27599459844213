import { Components, ItemComponent, StatusChange } from "./Api"

export const findLastStatusChange = (item: ItemComponent, components: Components): StatusChange | undefined => {
    const statusChanges = components.statusChanges && components.statusChanges[item.id]
    const lastStatusChange = (statusChanges && statusChanges.length > 0) ? statusChanges[statusChanges.length - 1] : undefined
    return lastStatusChange
}

export const findLastStatusChangeAsOf = (item: ItemComponent, threshold:number, components: Components): StatusChange | undefined => {
    const statusChanges = components.statusChanges && components.statusChanges[item.id]?.filter(sc=>sc.whenChanged <= threshold)
    const lastStatusChange = (statusChanges && statusChanges.length > 0) ? statusChanges[statusChanges.length - 1] : undefined
    return lastStatusChange
}