import { Alert, Box, Button, Card, CardContent, CardHeader, CircularProgress, Stack, Typography } from "@mui/material"
import React, { ReactNode, useEffect, useState } from "react"
import { get, send, sendFull } from "./Http"
import { useInterval } from "./react-hooks"


interface UserInfo {
  userId: string | undefined
  loginUrl: string | undefined
}


interface ConnectionState {
  isConnected:boolean
  userId: string | undefined
  loginUrl: string | undefined
}

export const getLoggedInUser = (): Promise<ConnectionState> => {
  return new Promise((resolveSuccess, resolveFailed) => {
    sendFull(
      "GET",
      "",
      "/whoami",
      (status, text) => {
        const u:UserInfo|undefined = status == 200 ? JSON.parse(text) : undefined
        
        
        resolveSuccess({
          isConnected: status == 200,
          userId: u?.userId,
          loginUrl: u?.loginUrl,
        })
      },
    )
  })
}

export default (props: { makeChildren?: ((userId: string) => ReactNode) | undefined, children?: ReactNode | undefined }) => {
  const { makeChildren } = props
  const [userId, setUserId] = React.useState<string>()
  const [status, setStatus] = useState<ConnectionState>()
  const [needsLogin, setNeedsLogin] = useState(false)


  const checkLogin = () => {
    getLoggedInUser()
      .then(state => {
        console.log("state", state)
        setStatus(state)
      })
  }

  
  useInterval(checkLogin, 3000)
  useEffect(checkLogin, [])

  if (!status) {
      return <CircularProgress/>
  } else if (!status.isConnected) {
    return <Alert color="error">Not Connected</Alert>
  } else if (status?.loginUrl) {
    const redirectToLogin = () => {
      if (status.loginUrl) {
        (window as Window).location = status.loginUrl
      }
    }
      return <>
      <Box style={{ textAlign: "center", marginTop: "100px" }}>
        <Card style={{ display: "inline-block", minWidth: "400px", minHeight: "400px" }}>
          <CardHeader>

          </CardHeader>
          <CardContent>
            <Stack spacing={4}>

              <Typography variant="h4">Login Needed</Typography>
              <Box>
                <Button variant="contained" onClick={redirectToLogin}>Login</Button>
              </Box>
            </Stack>
          </CardContent>
        </Card>
      </Box>
      </>
  } else if (status.userId) {
    return <>{makeChildren ? makeChildren(status.userId) : props.children}</>
  }else{
    return <><CircularProgress /> ??? </>
  }
}
