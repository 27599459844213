import { ReactNode } from 'react';
import { PlanSpecificationRequest } from './Api';
import { ApiCredentialsEditor, GeneralPlanSettingsEditorProps, IdentityEditor } from './GeneralPlanSettingsEditor';
import SettingsPane from './SettingsPane';

export const useSobSettingsPanels = (props: {value:PlanSpecificationRequest, onChange:(v:PlanSpecificationRequest)=>void}):Record<string, (p:GeneralPlanSettingsEditorProps)=>ReactNode> => {

    const { value, onChange } = props

    const handleJsonChange:React.ChangeEventHandler<HTMLTextAreaElement> = (event) => {
        const json = event.target.value
        console.log("New value ", json);

        try {
            const parsed = JSON.parse(json)

            onChange({
                ... value,
                config:{
                    ... value.config,
                    storiesOnBoard:parsed
                }
            })
        } catch (e) {
            console.log(e)
        }
    }

    return {

        "Identity":({ value, onChange })=><IdentityEditor value={value} onChange={onChange}/>,
        "Stories-On_Board":({ value, onChange })=><SettingsPane name="Stories-On-Board Configuration">
            <textarea
                style={{ "minHeight": "400px", "minWidth": "400px" }}
                value={JSON.stringify(value?.config?.storiesOnBoard, null, 4)}
                onChange={handleJsonChange} />
        </SettingsPane>,
        "API Publishing":({ value, onChange })=><ApiCredentialsEditor value={value} onChange={onChange}/>
    }
}



