import { YearMonthDay } from "./YearMonthDay"

export interface StoriesOnBoardConfig{
    username: string,
    password: string,
    orgName: string,
    boardName: string,
    storyMapId: string,
    firstIterationDate:string|undefined,
    iterationLengthInDays:number|undefined
}

export interface JiraCredentials{
    username:string
    password:string
}

export interface JiraSourceSettings{
    boardId:string
    url:string
    storyPointsField:string
    auth:JiraCredentials
    iterations: IterationsConfig|undefined
    fixedStorySize:number|undefined
}

export interface BasicCredentials{
    user:string,
    secret:string
}

export interface IterationsConfig{
    firstIterationDate:YearMonthDay
    iterationLengthInDays:number
}

export interface ComponentsConfig{
    iterations:IterationsConfig | undefined
    itemsAreEditable:boolean | undefined
    manualIds:boolean | undefined
}

export interface PlanConfig {

     storiesOnBoard:StoriesOnBoardConfig | undefined,
     jira:JiraSourceSettings|undefined,
     components?:ComponentsConfig,
     basicCredentials:BasicCredentials
     notBefore:number|undefined
}
export interface PlanSpecificationRequest{
     name:string,
     source:string,
     config:PlanConfig
}

export interface PlanOverviewDto {
    id:string,
    name:string
    kind:string|undefined
    source:string|undefined
    externalUrl:string|undefined
    lastFetch:number|undefined
}


export interface ItemsPatch {
    additions:ItemComponent[]
    updates:ItemComponent[]
    deletions:string[]
}


export interface ItemComponent {
    id: string,
    url: string | undefined,
    title: string,
    description: string,
    descriptionMimeType: string,
    data: any | undefined
}

export enum InitialBacklogPosition {
    top = "top", 
    next = "next", 
    bottom = "bottom",
}
export interface NewItemComponent {
    position:InitialBacklogPosition,
    item:ItemComponent,
}

export enum ItemStatus {
    todo = "todo", 
    in_progress = "in_progress", 
    done = "done",
    deleted = "deleted"
}

export interface StatusChange{
    status:ItemStatus,
    whenChanged:number
}
export interface AspectRef {
    aspectId:string,
    valueId:string
}
export interface AspectValue{
     id:string,
     name:string,
}
export interface Aspect{
     id:string,
     name:string,
     description:string,
     values:AspectValue[],
}

export interface ReleaseRef{
    releaseId:string
}
export interface Release{
    id:string,
    name:string,
    description:string,
}
export interface Components {
    items: ItemComponent[],
    order: string[],
    estimates: { [key: string]: number | undefined },
    statusChanges: { [key: string]: StatusChange[] | undefined } | undefined,
    releaseAssociations:Record<string, ReleaseRef|undefined> | undefined,
    titles: { [key: string]: string | undefined },
    aspects:Aspect[] | undefined,
    releases:Release[] | undefined,
    aspectAssociations:Record<string, AspectRef[]>|undefined,
}
