
export const doInSeries = (operations:(()=>Promise<any>)[]):Promise<any>=>{
    let idx = 0
    
    const doNext = ():Promise<any>=>{
        const next = operations[idx]
        if(next){
            console.log("Doing", idx, "of", operations.length)
            idx +=1
            return next().then(doNext)
        }else{
            console.log("Doing nothing ... all done")
            return Promise.resolve()
        }
    }

    return doNext()
}