import { Alert, Box, Button, Card, CardActionArea, CardActions, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Stack, TextField, Typography,} from "@mui/material"
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { ReactNode, useEffect, useState } from "react"
import { Aspect, AspectValue, } from "./Api"
import {v4 as uuidv4} from 'uuid';

import _ from "underscore"
import { deleteAspectValue, getAspect, getAspectValues, } from "./api-actions";
import AspectValueDialog from "./AspectValueDialog";

export default (props: { planId:string, aspectId:string , onChange:()=>void, onCancel: () => void }) => {
    const {planId, aspectId, onChange, onCancel } = props
    const [values, setValues] = React.useState<AspectValue[]>()
    const [aspectName, setAspectName] = React.useState<string>()
    const [selectedValue, setSelectedValue] = useState<ValueEditingSession>()
    const [error, setError] = React.useState<any>()


    const reload = ()=>{

        getAspectValues(planId, aspectId)
            .then(setValues)
            .catch(setError)

        getAspect(planId, aspectId)
            .then(a=>setAspectName(a.name))
            .catch(setError)
    }

    useEffect(reload, [])

    const makeEditor = ()=>{
        if(values){
            return <>

                <Stack spacing={2} style={{marginTop:"20px"}}>
                {values.map(v=>{
                    const startEdit = ()=>{
                        setSelectedValue({existing:v}); 
                    }
                    const doDelete = ()=>{
                        if(window.confirm("Are you sure?  This delete cannot be undone.")){
                            setError(undefined)
                            deleteAspectValue(planId, aspectId, v.id)
                                .then(reload)
                                .catch(setError)
                        }
                    }
                    return <Card>
                        <CardActionArea onClick={startEdit}>
                            <CardContent>
                            <Typography>{v.name}</Typography></CardContent>
                        </CardActionArea>
                            <CardActions>
                                <IconButton onClick={startEdit}><EditIcon/></IconButton>
                                <IconButton onClick={doDelete}><DeleteIcon/></IconButton>
                            </CardActions>
                        </Card>
                })}
                <Box><IconButton onClick={()=>setSelectedValue({existing:undefined})}><AddIcon/></IconButton></Box>
            </Stack>
            </>
        }
    }


    const closeValueEditor = ()=>{
        setSelectedValue(undefined);
    }


    return (<Dialog open={true} fullWidth={true} fullScreen={true}>
        <DialogTitle>"{aspectName}" Values</DialogTitle>

        <DialogContent>
            {error && <Alert color="error">{error?.toString()}</Alert>}
            {selectedValue && <AspectValueDialog planId={planId} aspectId={aspectId} value={selectedValue?.existing} onUpdate={()=>{closeValueEditor() ; onChange() ;reload()}} onCancel={()=>closeValueEditor()}/>}
            {!values && <CircularProgress/>}
            {makeEditor()}
        </DialogContent>
        <DialogActions>
            <Button onClick={onCancel}>Done</Button>
        </DialogActions>
    </Dialog>)
}

interface ValueEditingSession {
    existing:AspectValue|undefined
}

