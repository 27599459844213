import { Link, Navigate, useParams } from "react-router-dom"
import PlanScreenLayout from "./PlanScreenLayout"
import { useEffect, useState } from "react"
import { getActualPlan, getComponents, getCurrentIteration, getPlanConfig, getPlanOverview } from "./api-actions"
import { Alert, Box, Card, CardContent, CircularProgress, IconButton, MenuItem, Stack, Typography } from "@mui/material"
import { PlanSubmenu } from "./PlanSubmenu"
import PlanTimeSelectDialog from "./PlanTimeSelectDialog"
import Grid from "@mui/material/Unstable_Grid2/Grid2"
import { Components, PlanConfig, PlanOverviewDto } from "./Api"
import { makeReleaseBreakdownUrl } from "."
import PlanControlsBand from "./PlanControlsBand"
import ReactMarkdown from "react-markdown"
import linkify from "markdown-linkify"
import AddReleaseDialog from "./AddReleaseDialog"
import EditIcon from '@mui/icons-material/Edit';
import MarkdownContent from "./MarkdownContent"


export default () => {
    const { planId, releaseId } = useParams()

    const [isFetching, setFetching] = useState(true)
    const [plan, setPlan] = useState<PlanOverviewDto>()
    const [components, setComponents] = useState<Components>()
    const [error, setError] = useState<string>()
    const [overview, setOverview] = useState<PlanOverviewDto>()
    const [config, setConfig] = useState<PlanConfig>()
    const [showAddRelease, setShowAddRelease] = useState(false)
    const versionId = "current"

    const refresh = () => {
        setFetching(true)

        if (planId) {
            Promise.all([
                getPlanOverview(planId).then(setOverview),
                getActualPlan(planId).then(setPlan),
                getComponents(planId, versionId).then(setComponents),
                getPlanConfig(planId).then(setConfig)
            ]).catch(e => {
                console.log("Error:::", e)
                setError(e)
            }).finally(() => setFetching(false))
        }
    }

    useEffect(refresh, [planId, releaseId])

    const release = components?.releases?.find(r => r.id == releaseId)!!

    return (<>
        {showAddRelease && <AddReleaseDialog existing={release} planId={planId!!} onChange={() => { refresh(); setShowAddRelease(false) }} onClose={() => setShowAddRelease(false)} />}

        {(planId && overview && components) && <PlanScreenLayout
            overview={overview}
            controls={<>{isFetching && <CircularProgress />}</>}
            title={"Release: " + release?.name}
            actionsBar={<>
                {isFetching && <CircularProgress />}
                {config && <PlanControlsBand
                    planId={planId!!}
                    versionId={versionId}
                    isEditable={true}
                    plan={plan}
                    config={config}
                    components={components}
                    respondToUpdates={refresh}
                />}
            </>}
        >
            {error && <Alert color="error">{error}</Alert>}



            <Box style={{ minHeight: "40px" }}>
                {/* Spacer */}
            </Box>

            <Stack spacing={1}>
                <Box style={{ margin: "10px" }}><IconButton onClick={() => setShowAddRelease(true)}><EditIcon /></IconButton></Box>
                {release && <Box><MarkdownContent >{
                    release.description
                }</MarkdownContent></Box>}
            </Stack>
        </PlanScreenLayout>
        }</>)
}