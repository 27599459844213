import { Box } from "@mui/material"
import { Stack } from "@mui/system"
import { Components, PlanConfig, PlanOverviewDto } from "./Api"
import PlanActions from "./PlanActions";
import PlanFab from "./PlanFab";

export default (props:{planId:string, versionId:string, isEditable:boolean, plan:PlanOverviewDto|undefined, config:PlanConfig, components:Components | undefined, respondToUpdates:()=>void})=>{
    const {planId, versionId, isEditable, plan, config, components, respondToUpdates} = props

    return (<>
            <Stack direction="row" style={{ textAlign: "right"}} justifyContent={"flex-end"}>
                {plan && <PlanActions versionId={versionId} plan={plan} components={components} onPlanChange={respondToUpdates} />}
                <Box style={{ width: "10px" }} /> {/* spacer */}
                <PlanFab
                    planId={planId!!} 
                    isEditable={isEditable} 
                    config={config} 
                    components={components}
                    respondToUpdates={respondToUpdates}/>
            </Stack>
    </>)
}