import { Alert, Divider, Stack, Typography } from "@mui/material"

export default (props:{name:string, description?:string,  children?: React.ReactNode})=>{
    const {name, description, children} = props

    return (<div style={{
        display: "block",
        width:"500px",
        margin: "20px",
        marginTop: "40px",
        verticalAlign: "top"}}>
        {/* <Divider >{name}</Divider> */}
        <Stack spacing={3}>
            <div></div>
            {description && <Alert severity="info">{description}</Alert>}
            {children}
        </Stack>
    </div>)
}