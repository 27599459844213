import {
  Navigate,
  NavigateProps,
  Params,
  To,
  useParams,
} from "react-router-dom";

export default (props:{to:(params: Readonly<Params<string>>)=>string|To } &  Omit<NavigateProps, 'to' >) => {
  const params = useParams();
  
  const navProps = {
    ... params,
    to : props.to(params)
  } as NavigateProps

  return <Navigate {...navProps} />
}
