import { DatePickerProps } from "@mui/x-date-pickers"
import moment from "moment"

export interface YearMonthDay {
  year: number
  month: number
  day: number
}

export const toYearMonthDay = (v: moment.Moment): YearMonthDay => {
  return {
    year: v.year(),
    month: v.month() + 1,
    day: v.date(),
  }
}
export const stringifyYearMonthDay = (v: YearMonthDay): string => {
  return `${v.year}-${v.month}-${v.day}`
}
