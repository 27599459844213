

import React, { useEffect, useState } from 'react';
import { get, post, put } from './Http';
import { Button, Dialog, DialogContent, Typography, Stack, DialogTitle, Box, Card, CardContent, CardActionArea, IconButton, DialogActions, CircularProgress } from '@mui/material';
import { Aspect, Components } from './Api';
import AspectDialog from './AspectDialog';
import AddIcon from '@mui/icons-material/Add';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { getComponents } from './api-actions';
import AspectValuesDialog from './AspectValuesDialog';
import MarkdownContent from './MarkdownContent';

const saveAspect = (item: Aspect, planId: string): Promise<Aspect> => {
    return new Promise((resolveSuccess, resolveFail) => {
        const handleSuccess = (s: string) => resolveSuccess(JSON.parse(s) as Aspect)
        if (item.id && item.id.trim().length > 0) {
            put(JSON.stringify(item),
                `/api/plans/${planId}/components/aspects/${item.id}`,
                handleSuccess,
                resolveFail,
            )
        } else {
            post(JSON.stringify(item),
                `/api/plans/${planId}/components/aspects`,
                handleSuccess,
                resolveFail,
            )
        }
    })
}

export const AspectsSettingsPanel = (props: { planId: string, onChange: () => void, onClose: () => void }) => {
    const { planId, onChange, onClose } = props
    const [aspectInFocus, setShowAspectDialog] = useState<Aspect>()
    const [aspectValuesInFocus, setShowAspectValuesDialog] = useState<Aspect>()
    const [error, setError] = useState<string>()
    const [loading, setLoading] = useState(false)
    const [components, setComponents] = useState<Components>()
    const aspects = components?.aspects ?? []

    const refresh = ()=>{
        setLoading(true)
        getComponents(planId, "current")
            .then(setComponents)
            .catch(setError)
            .finally(()=>setLoading(false))
    }

    useEffect(refresh, [])

    const doAdd = () => {

        const newAspect: Aspect = {
            id: ``,
            name: "",
            description: "",
            values: [],
        }
        setShowAspectDialog(newAspect)
    }

    const startEdit = (a: Aspect) => {
        setShowAspectDialog(a)
    }

    const handleAspectEdit = (a: Aspect) => {
        saveAspect(a, planId)
            .then(saved => {
                setShowAspectDialog(undefined)
                onChange()
                refresh()
            })
            .catch(setError)
    }
    const hideAspect = () => {
        setShowAspectDialog(undefined)
    }

    return (<>
        {(aspectInFocus) && <AspectDialog existing={aspectInFocus} onComplete={handleAspectEdit} onCancel={hideAspect} />}
        {(aspectValuesInFocus) && <AspectValuesDialog planId={planId} aspectId={aspectValuesInFocus.id} onChange={onChange} onCancel={()=>setShowAspectValuesDialog(undefined)} />}
        <Dialog open={true} fullWidth={true} onClose={onClose}>
            <DialogTitle>Plan Aspects </DialogTitle>
            <DialogContent>
                {loading && <CircularProgress/>}
                {!loading && <>
                    <Stack spacing={2}>
                        {aspects.map(a => {
                            return <Card>
                                <CardContent>
                                    <Typography variant='h5'>{a.name}</Typography>
                                    <MarkdownContent>{a.description}</MarkdownContent>
                                </CardContent>
                                <CardActionArea>
                                    <Button onClick={() => startEdit(a)}>edit</Button>
                                    <Button onClick={() => setShowAspectValuesDialog(a)}>edit values</Button>
                                </CardActionArea>
                            </Card>
                        })}
                        <Box style={{textAlign:"center"}}>
                            <IconButton color='primary' onClick={doAdd}>
                                <AddIcon />
                            </IconButton>
                        </Box>
                    </Stack>
                </>}
            </DialogContent>
            {/* <DialogActions>
                <Stack direction="row-reverse">
                    <Button variant='outlined' onClick={onClose}>Done</Button>
                </Stack>
            </DialogActions> */}
        </Dialog>
    </>)
}