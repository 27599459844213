

import React, { useEffect, useState } from 'react';
import { get, post, put } from './Http';
import { Button, Dialog, DialogContent, Typography, Stack, DialogTitle, Box, Card, CardContent, CardActionArea, IconButton, DialogActions, CircularProgress, Alert, TextField } from '@mui/material';
import { Aspect, Components, Release } from './Api';
import { createNewRelease, getComponents, updateRelease } from './api-actions';


export default (props: { planId: string, existing?:Release, onChange: () => void, onClose: () => void }) => {
    const { planId, existing, onChange, onClose } = props
    const [error, setError] = useState<string>()
    const [loading, setLoading] = useState(false)
    const [components, setComponents] = useState<Components>()
    const [title, setTitle] = useState(existing?.name || "")
    const [description, setDescription] = useState(existing?.description || "")

    const refresh = () => {
        setLoading(true)
        getComponents(planId, "current")
            .then(setComponents)
            .catch(setError)
            .finally(() => setLoading(false))
    }

    useEffect(refresh, [])

    const doSave = () => {
        const newState:Release = {
            id: existing?.id || "",
            name: title,
            description: description,
        }

        const action = existing ? updateRelease(planId, newState) : createNewRelease(planId, newState)

        action.then(onChange)
    }

    return (<>
        <Dialog open={true} fullWidth={true} onClose={onClose}>
            <DialogTitle>Add/Edit Release</DialogTitle>
            <DialogContent>
                {loading && <CircularProgress />}
                {!loading && <>
                    <Stack spacing={2}>

                        {error && <Alert color="error">{error}</Alert>}
                        <TextField
                            margin="dense"
                            id="Title"
                            label="Title"
                            value={title}
                            onChange={e => setTitle((e.target.value))}
                            fullWidth
                            variant="standard"
                        />
                        <TextField
                            margin="dense"
                            id="Description"
                            label="Description"
                            value={description}
                            onChange={e => setDescription((e.target.value))}
                            fullWidth
                            multiline
                            rows={8}
                            variant="filled"
                        />
                    </Stack>
                </>}
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' onClick={onClose}>Cancel</Button>
                <Button variant='contained' onClick={doSave}>Save</Button>
            </DialogActions>
        </Dialog>
    </>)
}