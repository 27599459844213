import { Breadcrumbs, Stack, Typography } from "@mui/material"
import { CSSProperties, ReactNode } from "react"

export default (props: { pathHere: ReactNode[], pathName: ReactNode, title?: ReactNode, style?: CSSProperties }) => {
    const { pathName, pathHere, title, style } = props

    const segments = pathHere.concat(pathName ? [<Typography color="text.primary">{pathName}</Typography>] : [])
    return (
        <Stack spacing={3} style={style}>
            <Breadcrumbs>
                {pathHere.length > 0 && segments} hi
            </Breadcrumbs>
            {title && <Typography variant="h2">{title}</Typography>}
        </Stack>

    )
}
