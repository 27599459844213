import { Accordion, AccordionDetails, AccordionSummary, Alert, AppBar, Autocomplete, Box, Breadcrumbs, Button, Card, CardContent, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, FormControlLabel, IconButton, MenuItem, Select, Switch, TextField, Toolbar, Typography, createFilterOptions } from "@mui/material"
import { Stack } from "@mui/system"
import React, { CSSProperties, MouseEventHandler, ReactNode, useState } from "react"
import { Link, NavLink, useNavigate, useParams } from "react-router-dom"
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { Aspect, AspectRef, AspectValue, Components, ItemComponent, ItemStatus, ItemsPatch, PlanConfig, PlanOverviewDto, PlanSpecificationRequest, ReleaseRef, StatusChange } from "./Api"
import _ from "underscore"
import { VersionListEntry, getActualPlan, getComponents, getPlanConfig, getPlanOverview, getVersions, patchAspectAssignments, setReleases } from "./api-actions";
import { getReleaseSequence, ItemWithReleaseBlock, sameReleaseBlock } from "./release-sequencing";
import PlanScreenLayout, { breadcrumbStyle } from "./PlanScreenLayout";
import { PlanSubmenu } from "./PlanSubmenu";
import AddReleaseDialog from "./AddReleaseDialog";
import AddIcon from '@mui/icons-material/Add';
import PlanControlsBand from "./PlanControlsBand";
import { makeReleaseUrl } from ".";
import AspectValuesDialog from "./AspectValuesDialog";

export const Draggable = <T,>(props: { data: T, children: ReactNode }) => {
    const { data, children } = props
    return (<div
        draggable={true}
        onDragEnd={() => { }}
        onDragStart={e => { e.dataTransfer.setData("application/json", JSON.stringify(data)) }}
        style={{}}>
        {children}
    </div>)
}
const Droppable = <T,>(props: { style?: CSSProperties, children?: ReactNode, onDrop: (item: T) => void }) => {
    const { style, children, onDrop } = props
    const [hasHover, setHover] = React.useState(false)

    return (<div
        onDragEnter={e => { setHover(true); e.preventDefault() }}
        onDragOver={e => e.preventDefault()}
        onDragLeave={e => setHover(false)}
        onDragEnd={e => setHover(false)}
        onDrop={e => {
            const text = e.dataTransfer.getData("application/json")
            console.log("I was dropped-on?", text)
            const data: T = JSON.parse(text)
            onDrop(data)
            setHover(false)
        }}
        style={{
            opacity: 1,
            background: (hasHover ? "rgb(239 239 239)" : "white"),
            ...style
        }}
    >
        {children}
    </div>)
}

export default (props: {}) => {

    const { planId, aspectId } = useParams()
    const [isFetching, setFetching] = React.useState(true)
    const [versionId, setVersionId] = useState("current")
    const [components, setComponents] = React.useState<Components>()
    const [plan, setPlan] = React.useState<PlanOverviewDto>()
    const [overview, setOverview] = React.useState<PlanOverviewDto>()
    const [config, setConfig] = React.useState<PlanConfig>()
    const [error, setError] = React.useState<string>()
    const [rawVersions, setVersions] = React.useState<VersionListEntry[]>()
    const [showAddRelease, setShowAddRelease] = React.useState(false)
    const [aspectValuesInFocus, setAspectValuesInFocus] = useState<string>()

    const refresh = () => {
        setFetching(true)

        if (planId) {
            Promise.all([
                getVersions(planId).then(setVersions),
                getActualPlan(planId).then(setPlan),
                getPlanConfig(planId).then(setConfig),
                getPlanOverview(planId).then(setOverview),
                getComponents(planId, versionId).then(setComponents),
            ]).catch(e => {
                console.log("Error:::", e)
                setError(e)
            }).finally(() => setFetching(false))
        }
    }

    React.useEffect(refresh, [planId, versionId])

    const aspects = components?.aspects ?? []
    const analysisAspect = aspects.find(a => a.id == aspectId)

    if (!overview || !config || !components) {
        return <CircularProgress />
    } else if (!analysisAspect) {
        return <>No such aspect: {aspectId}</>
    }

    const aspectAssociations = components?.aspectAssociations ?? {}

    const sequencing = getReleaseSequence(components)

    const defaultValue: AspectValue = { id: "??", name: "??" }
    const defaultValueRef: AspectRef = { aspectId: analysisAspect.id, valueId: defaultValue.id }
    const aspectValues = analysisAspect.values.concat([defaultValue])

    const sortedAspectValues = [...aspectValues].sort((a, b) => a.name.localeCompare(b.name))

    const headerRowStyles: CSSProperties = {
        textAlign: "center",
        padding: "20px",
        alignSelf: "flex-start",
        position: "sticky",
        top: 0,
        background: "white",
    }
    return (<>
        {aspectValuesInFocus && <AspectValuesDialog planId={planId!!} aspectId={aspectValuesInFocus} onChange={refresh} onCancel={() => setAspectValuesInFocus(undefined)} />}

        {showAddRelease && <AddReleaseDialog planId={planId!!} onChange={() => { refresh(); setShowAddRelease(false) }} onClose={() => setShowAddRelease(false)} />}
        <PlanScreenLayout
            overview={overview}
            controls={undefined}
            actionsBar={<>
                {isFetching && <CircularProgress />}
                {config && <PlanControlsBand
                    planId={planId!!}
                    versionId={versionId}
                    isEditable={true}
                    plan={plan}
                    config={config}
                    components={components}
                    respondToUpdates={refresh}
                />}
            </>}
        >
            <PlanSubmenu planId={planId!!} components={components} />
            <Typography variant="overline" style={{ textAlign: "center" }}>Releases by '{analysisAspect.name}'</Typography>

            <Box style={{ minHeight: "40px" }}>
                {/* Spacer */}
            </Box>
            <Grid container columns={aspectValues.length + 1} minWidth={`${sortedAspectValues.length * 200}px`}>

                <Grid
                    xs={1}
                    direction="row"
                    display="flex"
                    alignItems="center"
                    justifyContent={"flex-start"}
                >
                    <Box style={{ margin: "10px" }}><IconButton onClick={() => setShowAddRelease(true)}><AddIcon /></IconButton></Box>
                </Grid>
                {sortedAspectValues.map(aspectValue => {
                    return (<>
                        <Grid xs={1} style={headerRowStyles}>
                            <Link to="" style={breadcrumbStyle} onClick={() => setAspectValuesInFocus(aspectId)} className="underline-on-hover">{aspectValue.name}</Link>
                        </Grid>
                    </>)
                })}
                {sequencing.releasesInOrder.map(releaseBlock => {
                    const release = releaseBlock?.releaseId ? components.releases?.find(r => r.id == releaseBlock.releaseId)!! : undefined
                    const itemsForRelease = sequencing.itemsWithRelease.filter(({ item, release }) => {
                        return sameReleaseBlock(releaseBlock, release)
                    })

                    const hasAspect = (item:ItemComponent, aspectValue:AspectValue):boolean => {
                        const aspectVersion = aspectAssociations[item.id]?.find(a => a.aspectId == analysisAspect.id) ?? defaultValueRef
                        return aspectVersion.valueId == aspectValue.id
                    }

                    return (<>
                        <Grid xs={1} style={{ textAlign: "left", borderTop: "1px solid rgb(220 220 220)", borderRight: "1px solid rgb(240 240 240)" }}>
                            <Stack style={{ margin: "20px" }} spacing={1}>
                                {release ? <Link to={makeReleaseUrl(planId!!, release.id)} style={breadcrumbStyle} className="underline-on-hover">{release.name}</Link> : `??`}
                                {(release && itemsForRelease.length > 0) && <>
                                    <Typography variant="caption">{itemsForRelease.length} items</Typography>
                                </>}
                            </Stack>

                        </Grid>
                        {sortedAspectValues.map(aspectValue => {
                            const matchingItems = sequencing.itemsWithRelease.filter(({ item, release }) => {
                                return sameReleaseBlock(releaseBlock, release) && hasAspect(item, aspectValue)
                            })

                            const handleDrop = (item:ItemWithReleaseBlock)=>{
                                console.log(`Need to move item ${item.item.id} to "${release?.name}" and "${aspectValue.name}"`)

                                // Set the release
                                if(release){
                                    if(item.release.releaseId != release?.id){
                                        const releaseRef:ReleaseRef = {
                                            releaseId:release.id
                                        }
                                        const updatedEstimates = {
                                            [item.item.id]: releaseRef
                                        }
                                        setFetching(true)
                                        setReleases(planId!!, updatedEstimates)
                                            .then(refresh)
                                            .catch(setError)
                                    }    
                                }

                                // Set the aspect
                                const updateAspectForItem = (item:ItemComponent, aspectId:string, aspectValue: AspectValue|undefined) => {
                                    const aspectAssociations = (components.aspectAssociations ?? {})
                                    const aspectRefs = aspectAssociations[item.id] ?? []
                                    const newRef: AspectRef = {
                                        aspectId: aspectId,
                                        valueId: aspectValue?.id !!, // hack .. the API should be updated to account for the fact that we're "unsetting"
                                    }
                                    const updatedAssociations = aspectRefs.filter(r => r.aspectId != aspectId).concat([newRef])

                                    setFetching(true)

                                    patchAspectAssignments(planId!!, { [item.id]: updatedAssociations })
                                        .then(refresh)
                                        .catch(setError)
                                }

                                if(aspectId && !hasAspect(item.item, aspectValue)){
                                    console.log("Needs aspect change to ", aspectValue)
                                    updateAspectForItem(item.item, aspectId, aspectValue)
                                }

                            }


                            

                            return (<>
                                <Grid xs={1} style={{ textAlign: "center", border: "1px solid rgb(245 245 245)", borderTop: "1px solid rgb(220 220 220)" }}>
                                    <Droppable onDrop={handleDrop} style={{ width: "100%", height: "100%" }}>
                                        <Stack spacing={1} style={{ padding: "20px" }}>
                                            {matchingItems.map(i => {
                                                return (<>
                                                    <Draggable data={i}>
                                                        <Card>
                                                            <CardContent>
                                                                <Link style={{ color: '#222' }} className="underline-on-hover" to={`/planview/${planId}/items/${i.item.id}`}>{i.item.title}</Link>
                                                            </CardContent>
                                                        </Card>
                                                    </Draggable>
                                                </>)
                                            })}
                                        </Stack>
                                    </Droppable>
                                </Grid>
                            </>)
                        })}
                    </>)
                })}
            </Grid>
        </PlanScreenLayout>
    </>)
}