import { useEffect, useState } from "react"
import { Iteration, getIterations } from "./api-actions"
import { Alert, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, MenuItem, Radio, RadioGroup, Stack } from "@mui/material"
import { DateTimePicker } from "@mui/x-date-pickers"
import moment from "moment"
import LabeledSelect from "./LabeledSelect"

const getIterationByStartTime = (start: number, iterations: Iteration[]) => iterations.find(i => i.start === start)
export default (props: { planId: string, value: number | undefined, onCancel: () => void, onSelect: (timestamp: number) => void }) => {
    const { planId, onSelect, onCancel } = props
    const [error, setError] = useState<string>()
    const [value, setValue] = useState(props.value)
    const [mode, setMode] = useState<"iteration-start" | "custom">()
    const [iterations, setIterations] = useState<Iteration[]>()

    const refresh = () => {
        if (planId) {
            Promise.all([
                getIterations(planId).then(result => {
                    result.reverse()
                    const iteration = value ? getIterationByStartTime(value, result) : undefined
                    console.log("FOund iteration for", value, iteration)
                    setIterations(result)
                    setMode(iteration ? "iteration-start" : "custom")
                }),
            ]).catch(e => {
                console.log("Error:::", e)
                setError(e)
            })
        }
    }

    useEffect(refresh, [])


    return (<Dialog open={true} fullWidth={true} fullScreen={false}>
        <DialogTitle>Select Time</DialogTitle>

        <DialogContent style={{ paddingTop: "20px" }}>
            <Stack spacing={3}>
                {error && <Alert color="error">{error}</Alert>}
                {!(iterations && mode) && <CircularProgress />}
                {(iterations && mode) && <>
                    <FormControl>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            value={mode}
                            name="radio-buttons-group"
                            onChange={(e, v) => { setMode(v as any) }}
                        >
                            <FormControlLabel value={"iteration-start"} control={<Radio />} label="Start of Iteration" />
                            <FormControlLabel value={"custom"} control={<Radio />} label="Specific time" />
                        </RadioGroup>
                    </FormControl>

                    {(mode == "custom" && <DateTimePicker label="Progress Since" value={moment(value)} onChange={v => setValue(v ? v.unix() * 1000 : undefined)} />)}
                    {(mode == "iteration-start") &&
                        <LabeledSelect
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={value}
                            label="Iteration Starting"
                            onChange={e => setValue(e.target.value as any)}
                        >
                            {iterations?.map(i => {
                                return <MenuItem value={i.start}>{new Date(i.start).toLocaleString()}</MenuItem>
                            })}

                        </LabeledSelect>
                    }
                </>}

            </Stack>
        </DialogContent>
        <DialogActions>
            <Button onClick={onCancel}>Cancel</Button>
            <Button variant="contained" disabled={!value} onClick={() => { value && onSelect(value) }}>Apply</Button>
        </DialogActions>
    </Dialog>)
}
