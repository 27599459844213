import { FormControl, InputLabel, Select, SelectProps } from "@mui/material"

export interface LabeledSelectProps<T> extends SelectProps<T> {
    labelId: string
    label: string
    fullWidth?: boolean
}

export default <T,>(props: LabeledSelectProps<T>) => {
    return (<FormControl fullWidth={props.fullWidth === undefined ? true : props.fullWidth}>
        <InputLabel id={props.labelId}>{props.label}</InputLabel>
        <Select
            {...props}
            labelId={props.labelId}
            label={props.label}>
            {props.children}
        </Select>
    </FormControl>)
}