import { useState } from "react"
import { PlanOverviewDto, PlanConfig } from "./Api"
import { CircularProgress, IconButton, Tooltip } from "@mui/material"
import SettingsIcon from '@mui/icons-material/Settings';
import { get } from './Http';
import PlanEditDialog from "./PlanEditDialog";

export default (props: { plan: PlanOverviewDto, onReload: () => void }) => {
  const { plan, onReload } = props

  const [isWorking, setWorking] = useState(false)
  const [dialogFocus, setDialogFocus] = useState<PlanConfig>()
  if (isWorking) {
    return <CircularProgress size={40} />
  } else {

    return <>
      <Tooltip title="Settings">
        <IconButton onClick={() => get("/plans/" + plan.id, setDialogFocus)}  >
          <SettingsIcon />
        </IconButton>
      </Tooltip>
      {(dialogFocus && plan.source) &&
        <PlanEditDialog
          existing={true}
          currentValue={{
            name: plan.id,
            source: plan.source,
            config: dialogFocus
          }}
          handleSuccess={() => {
            onReload()
            setDialogFocus(undefined)
          }}
          handleClose={() => setDialogFocus(undefined)}
        />}
    </>
  }
}