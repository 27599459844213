import { IconButton, Tooltip } from '@mui/material';

import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import DataObjectIcon from '@mui/icons-material/DataObject';
import CloudIcon from '@mui/icons-material/Cloud';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Components, PlanConfig, PlanOverviewDto } from './Api';
import FetchIcon from './FetchIcon';
import TuneIcon from '@mui/icons-material/Tune';
import PlanSettingsButton from './PlanSettingsButton';
import { AspectsSettingsPanel } from './AspectsPanel';
import { useEffect, useState } from 'react';
import { getPlanConfig } from './api-actions';

export default (props: { versionId:string, plan: PlanOverviewDto, components?:Components, onPlanChange: () => void }) => {
    const { versionId, plan, onPlanChange } = props

    const [showAspectsDialog, setShowAspectsDialog] = useState(false)
    const [config, setConfig] = useState<PlanConfig>()
    const [isFetching, setFetching] = useState(true)
    const [error, setError] = useState<string>()

    const planId = plan.id
    const dataUrl = "/plans/" + plan.id + "/current?format=legacyburn"
  
    const refresh = ()=>{
        Promise.all([
            getPlanConfig(planId).then(setConfig),
        ]).catch(e=>{
            console.log("Error:::", e)
            setError(e)
        }).finally(()=>setFetching(false))
    }

    useEffect(refresh, [])

    return (<>
  
        <Tooltip title="Burninator">
  
          <IconButton onClick={() => {
            window.open(`https://burn.appfoo.io/board/${plan.id}`, "_blank")
          }} >
            <LocalFireDepartmentIcon />
          </IconButton>
        </Tooltip>
  
        <Tooltip title="Data">
          <IconButton onClick={() => window.open(dataUrl, "_blank")} >
            <DataObjectIcon />
          </IconButton>
        </Tooltip>
        {plan.externalUrl && <Tooltip title={`${plan.kind}`}>
          <IconButton onClick={() => {window.open(plan.externalUrl, "_blank")}} ><CloudIcon /></IconButton>
        </Tooltip>}
        {plan.kind !== "Simple" && <FetchIcon plan={plan} onReload={onPlanChange} />}
        {(config && isEditable(versionId, config) && config?.components && config.components?.itemsAreEditable) && <Tooltip title="Aspects"><IconButton onClick={()=>setShowAspectsDialog(true)}><TuneIcon/></IconButton></Tooltip>}
        <PlanSettingsButton plan={plan} onReload={onPlanChange} />
        {(showAspectsDialog && config?.components) && <AspectsSettingsPanel planId={plan.id} onChange={onPlanChange} onClose={()=>setShowAspectsDialog(false)} />}

    </>)
  }

  const isEditable = (versionId:string, config:PlanConfig)=>  (versionId == "current") && (config?.components?.itemsAreEditable == true)