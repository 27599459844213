import './App.css';
import logo from './logo.png'
import React, { useEffect, useState } from 'react';
import { get } from './Http';
import { CircularProgress, Stack, Box, Card, CardContent, Typography, CardActionArea, CardActions } from '@mui/material';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import PlanEditDialog from './PlanEditDialog';
import { PlanOverviewDto, PlanSpecificationRequest } from './Api';
import { Link, useNavigate, useParams } from 'react-router-dom';
import HeaderThing from './HeaderThing';
import { fetchPlans } from './api-actions';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { useViewport } from 'react-viewport-hooks';
import PlanActions from './PlanActions';
import AddPlanButton, { Focus } from './AddPlanButton';
import { makePlanUrl } from '.';

interface PlansFetch {
  fetching: boolean
  plans: PlanOverviewDto[] | null
}


export const GroupView = (props: { userId: string }) => {
  const { groupId } = useParams()
  const { userId } = props
  const [currentFocus, setFocus] = React.useState<Focus>()
  const [plansFetch, setPlansFetch] = React.useState<PlansFetch>({
    fetching: false,
    plans: null
  })
  const { vw, vh } = useViewport()
  const navigate = useNavigate()

  const refreshPlans = () => {
    if (userId && !plansFetch.fetching && !plansFetch.plans) {

      setPlansFetch({
        ...plansFetch,
        fetching: true
      })

      fetchPlans().then(result => {
        setPlansFetch({
          fetching: false,
          plans: groupId ? result.filter(p => p.source == groupId) : result
        })
      })
    }
  }


  React.useEffect(refreshPlans, [userId, plansFetch, groupId])

  let reloadHome = () => {
    setFocus(undefined)
    setPlansFetch({
      fetching: false,
      plans: null
    })
  }


  if (!userId) {
    return <div style={{ textAlign: "center", marginTop: "10%" }}>
      <CircularProgress />
    </div>
  } else {
    const plans = plansFetch?.plans
    const sortedPlans = plans && [...plans].sort((a,b)=>a.name.localeCompare(b.name))

    const mainView = <>
      <Stack spacing={3} style={{ margin: "30px" }}>
        {sortedPlans?.map(p => {
          const plan: PlanOverviewDto = p 

          const planUrl = makePlanUrl(plan.id)

          return <><Card >
            <CardActionArea onClick={(e) => {
              console.log("direct navigating to ", planUrl)
              navigate(planUrl)
            }}>
              <CardContent style={{ textAlign: "left" }}>
                <Grid container spacing={1}>
                  <Grid xs={12} md={6} >
                    <Typography variant={"h5"} style={{ color: '#222' }}>
                      {plan.name}
                    </Typography>
                  </Grid>
                  <Grid xs={12} md={6} style={{ textAlign: (vw > 800 ? "right" : "left") }}>

                  </Grid>
                </Grid>
              </CardContent>

            </CardActionArea>
            <CardActions>
              <Box><PlanActions versionId={"current"} plan={plan} onPlanChange={reloadHome}/></Box>
            </CardActions>
          </Card>
          </>
        })}
      </Stack>
    </>


    let dialog;
    if (currentFocus?.plan) {
      let { existing, plan } = currentFocus
      console.log("currentFocus", currentFocus)

      dialog = <PlanEditDialog
        existing={existing}
        currentValue={plan}
        handleSuccess={reloadHome}
        handleClose={() => setFocus(undefined)}
      />
    }

    return (
      <div className="App" >
        {dialog}
        <div style={{ textAlign: "left" }}>
          <AddPlanButton setFocus={setFocus} />
          {!groupId && <HeaderThing
            style={{ margin: "20px" }}
            pathHere={[]}
            pathName="groups"
            title={<>Groups</>} />}
          {groupId && <>
            <HeaderThing
              style={{ margin: "20px" }}
              pathHere={[<Link style={{ color: '#222' }} to="/">orgs</Link>]}
              pathName={groupId}
              title={<>{groupId}</>} />

          </>}
        </div>

        {plansFetch?.plans ? mainView : <CircularProgress />}

      </div>
    );
  }
}

function onlyUnique<T>(value: T, index: number, array: T[]) {
  return array.indexOf(value) === index;
}

const getPlans = (): Promise<PlanSpecificationRequest[]> => {
  return new Promise((resolveSuccess, resolveFailed) => {
    get("/plans", resolveSuccess)
  })
}



export const GroupsView = (props: { userId: string }) => {

  const [plans, setPlans] = useState<PlanSpecificationRequest[]>()
  const [plan, setPlan] = useState<PlanSpecificationRequest>()

  const groups = plans?.map(p => p.source).filter(onlyUnique).sort()

  const reload = () => {
    getPlans().then(setPlans)
  }
  useEffect(reload, [])

  return (<>
    {plan && <PlanEditDialog
      existing={false}
      currentValue={plan}
      handleSuccess={reload}
      handleClose={() => setPlan(undefined)}
    />}
    <HeaderThing
      style={{ marginTop: "20px", marginLeft: "20px" }}
      pathHere={[]}
      pathName="Plan"
      title="Organizations" />
    <AddPlanButton setFocus={focus => setPlan(focus.plan)} />
    <Stack spacing={3} style={{ margin: "20px", marginLeft: "30px" }}>
      {(!groups) && <CircularProgress />}
      {groups?.map(groupId => {
        return <Link to={`/groups/${groupId}`} style={{ color: "#333" }}>{groupId}</Link>
      })}
    </Stack>
  </>)
}
