
import { Button, TextField, FormControl, Divider, Typography, Stack, FormHelperText, FormControlLabel, Checkbox, Box, Stepper, Step, StepLabel, Alert } from '@mui/material';
import React, { ChangeEvent, ReactNode, useState } from 'react';
import { BasicCredentials, PlanConfig, PlanSpecificationRequest } from './Api';
import SettingsPane from './SettingsPane';
import { DateTimeField, DateTimePicker } from '@mui/x-date-pickers';
import moment, { Moment } from 'moment';

export interface GeneralPlanSettingsEditorProps {
    value: PlanSpecificationRequest
    onChange: (newValue: PlanSpecificationRequest) => void
}

export const IdentityEditor = (props:GeneralPlanSettingsEditorProps)=>{
    const top = props.value
    const handleChange = props.onChange

    return (
        <SettingsPane key="identity" name="Identity">
            <TextField label="Plan Name" variant="standard" fullWidth value={top.name} onChange={e => handleChange({ ...top, name: e.target.value })} />
            <TextField label="Access Group" variant="standard" fullWidth value={top.source} onChange={e => handleChange({ ...top, source: e.target.value })} />
            <FormHelperText>This is the group used for access control within other AppFoo tools</FormHelperText>
        </SettingsPane>)
}

export const ApiCredentialsEditor = (props:GeneralPlanSettingsEditorProps)=>{
    const {value} = props
    const top = props.value
    const config = props.value.config
    const credentials = props.value.config.basicCredentials
    const onChange = props.onChange
    const isMissingOrBlank = (s:string):boolean => (s?s.trim().length==0:true)
    const [enabled, setEnabled] = useState<boolean>(!isMissingOrBlank(credentials?.secret) && !isMissingOrBlank(credentials?.user))
    
    const handleConfigChange = (newValue: PlanConfig) => {
        onChange({
            ...top,
            config: newValue
        })
    }
    const handleBasicCredentialsChange = (newValue: BasicCredentials) => {
        handleConfigChange({
            ...config,
            basicCredentials: newValue
        })
    }

    const setThreshold = (m:Moment|undefined)=>{
        onChange({ ...value, config:{... value.config, notBefore: (m ? m?.unix() * 1000 : undefined)} })
    }
    return (
        <SettingsPane key="api-credentials" name="Advanced" >
            <Stack spacing={1}>
                <FormControlLabel
                value="start"
                control={
                    <Checkbox
                    checked={value.config.notBefore ? true : false}
                    onChange={()=>setThreshold(value.config.notBefore ? undefined : moment())}
                    inputProps={{ 'aria-label': 'controlled' }}
                    />}
                label="Ignore History Before Threshold"
                labelPlacement="end"
                />
                {value.config.notBefore && <DateTimeField variant="standard" label="Ignore Threshold" value={value.config.notBefore && moment(value.config.notBefore)} onChange={(m) => setThreshold(m as Moment)}/>}
            </Stack>
            <Box>
                <FormControlLabel
                value="start"
                control={
                    <Checkbox
                    checked={enabled}
                    onChange={()=>setEnabled(!enabled)}
                    inputProps={{ 'aria-label': 'controlled' }}
                    />}
                label="Enable External API Access"
                labelPlacement="end"
                />
            </Box>
            {enabled && <>
                <Alert color="warning">This enables other tools to access this data via the Plan API.  Do not enable unless you know what you're doing.</Alert>
                <TextField label="Username" variant="standard" fullWidth value={config?.basicCredentials?.user} onChange={e => handleBasicCredentialsChange({ ...credentials, user: e.target.value })} />
                <TextField label="Password" variant="standard" type="password" fullWidth value={config?.basicCredentials?.secret} onChange={e => handleBasicCredentialsChange({ ...credentials, secret: e.target.value })} />
            </>}
        </SettingsPane>
    )
}


interface SettingsEditorProps extends GeneralPlanSettingsEditorProps {
    panels:Record<string, (p:GeneralPlanSettingsEditorProps)=>ReactNode>
    onSave:()=>void
}
export const useSettingsEditor = (props:SettingsEditorProps):{content:ReactNode, buttons:ReactNode}=>{
    const {panels, value, onChange, onSave} = props
    const [panelIdx, setPanelIdx] = useState(0)
    const panelNames = Object.keys(panels)
    const lastPanelIdx = panelNames.length -1
    console.log("Rendering with panel ", panelIdx, "of", lastPanelIdx)
    const panelName = panelNames[panelIdx]
    const panel = panels[panelName](props)
    const goNext = ()=>{
        console.log("Hey")
        const n = Math.min(panelNames.length -1, panelIdx + 1)
        console.log("Moving to panel ", n)
        setPanelIdx(n)
    }

    const goBack = ()=>{
        console.log("Hey")
        const n = Math.max(0, panelIdx - 1)
        console.log("Moving to panel ", n)
        setPanelIdx(n)
    }

    return {
        content:(<Stack spacing={2}>
            <Stepper activeStep={panelIdx}>
            {panelNames.map((label, index) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: {
                optional?: React.ReactNode;
            } = {};
            return (
                <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
            );
            })}
        </Stepper>
        {panel}
        </Stack>),
        buttons:(<>
            {panelIdx !== 0 && <Button variant="outlined" onClick={goBack}>Back</Button>}
            {panelIdx !== lastPanelIdx && <Button variant="outlined" onClick={goNext}>Next</Button>}
            {panelIdx == lastPanelIdx && <Button variant="contained" onClick={onSave}>Save</Button>}
        </>)
    }
}