
import { Components, ItemComponent } from "./Api"
import { isDeleted, isDone } from "./BacklogView";

export interface ReleaseBlockHandle{
    releaseId:string|undefined
    unscheduledAfter:string|undefined
}
export const sameReleaseBlock = (a:ReleaseBlockHandle, b:ReleaseBlockHandle)=>{
    return a.releaseId == b.releaseId && a.unscheduledAfter == b.unscheduledAfter
}

export interface ItemWithReleaseBlock {
    item:ItemComponent
    release:ReleaseBlockHandle
}

export interface ReleaseSequencing{
    releasesInOrder:ReleaseBlockHandle[]
    itemsWithRelease:ItemWithReleaseBlock[]
}
export const getReleaseSequence = (components:Components):ReleaseSequencing=>{

    const releaseAssociations = components?.releaseAssociations ?? {}

    const visibleItems = components.items.filter(i => !isDeleted(i, components)).filter(i => !isDone(i, components))
    const itemsInOrder = components?.order.map(itemId=>{
        return visibleItems.find(i=>i.id == itemId)!!
    }).filter(i=> i)


    const releaseBlockForItem = (i:ItemComponent):ReleaseBlockHandle => {
        const r = releaseAssociations[i.id]
        const releaseBlock:ReleaseBlockHandle = r ? {releaseId:r.releaseId, unscheduledAfter:undefined} : {releaseId:undefined, unscheduledAfter:i.id}
        console.log("releaseBlock is ", JSON.stringify(releaseBlock), "for", r, r?true:false, i)
        return releaseBlock
    }

    const itemsWithRelease:ItemWithReleaseBlock[] = []
    let currentHandle:ReleaseBlockHandle|undefined = undefined
    itemsInOrder?.forEach(i=>{
        const releaseBlock:ReleaseBlockHandle = releaseBlockForItem(i)
        itemsWithRelease.push({
            item:i,
            release:releaseBlock,
        })
        if(!currentHandle || !sameReleaseBlock(currentHandle, releaseBlock)){
            currentHandle = releaseBlock
        }
    })


    const reversedItems = [... itemsWithRelease].reverse()
    let releasesInOrder:ReleaseBlockHandle[] = []
    reversedItems.forEach(({item, release})=>{
        if(!releasesInOrder.find(r=>sameReleaseBlock(r, release))){
            releasesInOrder = [release].concat(releasesInOrder) //push(release)
        }
    })

    components.releases?.forEach(release=>{
        const releaseId = release.id
        if(!releasesInOrder.find(rbh=>rbh.releaseId == releaseId)){
            releasesInOrder.push({
                releaseId:releaseId,
                unscheduledAfter:undefined
            })
        }
    })


    return {
        releasesInOrder,
        itemsWithRelease
    }
}
